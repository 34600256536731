import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./comman.scss";
import Overview from "./pages/Overview";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Client from "./pages/Client";
import Employee from "./pages/Employee";
import Purposal from "./pages/Purposal";
import CreateNewPurposal from "./pages/createNewPurposal";
import FinalCreate from "./pages/FinalCreate";
import AboutBatukPartener from "./pages/AboutBatukPartener";
import PersonalDetails from "./pages/PersonalDetails";
import ResetPassword from "./pages/ResetPassword";
import "sweetalert2/src/sweetalert2.scss";
import Header from "./components/Header";
import Earning from "./pages/Earnings";
import DashBoard from "./pages/DashBoard";
import KycForm from "./pages/KYCForm";
import About from "./pages/PublicRoutes/About";
import Blogs from "./pages/PublicRoutes/blogs/Blogs";
import Faq from "./pages/PublicRoutes/Faq";
import Contact from "./pages/PublicRoutes/Contact";
import IndiRegister from "./pages/IndiRegister";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import UploadFile from "./pages/UploadFile";
import ProductDetails from "./pages/ProductDetails";
import AddressDetails from "./pages/AddressDetails";
import SpotGold from "./pages/spotGold/SpotGold";
import CreateNewOrder from "./pages/spotGold/CreateNewOrder";
import Product from "./pages/spotGold/Product";
import Sip from "./pages/Sip/Sip";
import CreateNewSIP from "./pages/Sip/CreateNewSIP";
import FinalCreateSip from "./pages/Sip/FinalCreateSip";
import CompleteKYC from "./pages/CompleteKYC";
import StartFd from "./pages/fixed_deposit/StartFd";
import GoldFd from "./pages/fixed_deposit/GoldFd";
import CreateGoldFd from "./pages/fixed_deposit/CreateGoldFd";
import GoldFdDetails from "./pages/fixed_deposit/GoldFdDetails";
import Home from "./pages/home/Home";
import ViewBlog from "./pages/PublicRoutes/blogs/ViewBlog";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sell from "./pages/sell/Sell";
import CreateNewSell from "./pages/sell/CreateNewSell";
import FinalCreateSell from "./pages/sell/FinalCreateSell";
import SalesReports from "./pages/SalesReports";
import SipMining from "./pages/Reports/SipMining/SipMining";
import SipBusinessReport from "./pages/Reports/SipBusinessReport";
import FileImportHistory from "./pages/Reports/FileImportHistory";
import ComplianceReport from "./pages/Reports/ComplianceReport";
import InactiveClients from "./pages/Reports/InactiveClients";
import MissingSip from "./pages/Reports/MissingSip";
import InvestorView from "./pages/InvestorView/InvestorView";
import PageNotFound from "./pages/pageNotFound/PageNotFound";

function App() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      sessionStorage.setItem("token", token);
      navigate("/dashboard");
    } else {
      console.log("No token found in the URL.");
    }
  }, [location]);

  useEffect(() => {
    const publicRoutes = [
      "/login",
      "/register",
      "/indRegister",
      "/about",
      "/blog",
      "/faq",
      "/contact",
      "/forgotpassword",
      "/",
      "/view-blog",
      "/terms-conditions",
      "/privacy-policy",
    ];

    const currentPath = location.pathname;
    const isPublicRoute = publicRoutes.some((route) =>
      currentPath.startsWith(route)
    );

    if (!token && !isPublicRoute) {
      navigate("/");
    }
  }, [token, location, navigate]);

  return (
    <>
      <Routes>
        {/* Map "/" to the Home component */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Header />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/:title_url" element={<ViewBlog />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/IndRegister" element={<IndiRegister />} />
        <Route path="/page-not-found" element={<PageNotFound />} />

        {/* Map "/dashboard" to the DashBoard component */}
        {token && (
          <Route path="/dashboard" element={<DashBoard />}>
            <Route index element={<Overview />} />
            <Route path="/dashboard/client_table" element={<Client />} />
            <Route
              path="/dashboard/investor-view/:id"
              element={<InvestorView />}
            />
            <Route
              path="/dashboard/complete-kyc/:clientId/:productId"
              element={<CompleteKYC />}
            />
            <Route
              path="/dashboard/complete-kyc/:clientId"
              element={<CompleteKYC />}
            />
            <Route path="/dashboard/client_kyc" element={<KycForm />} />
            <Route path="/dashboard/employee_table" element={<Employee />} />
            <Route path="/dashboard/sip" element={<Sip />} />
            <Route path="/dashboard/gold-fd" element={<GoldFd />} />
            <Route path="/dashboard/start-gold-fd" element={<StartFd />} />
            <Route
              path="/dashboard/create-gold-fd/:clientId"
              element={<CreateGoldFd />}
            />
            <Route
              path="/dashboard/create-gold-fd/:clientId/:id"
              element={<GoldFdDetails />}
            />
            <Route path="/dashboard/proposal" element={<Purposal />} />
            <Route
              path="/dashboard/create_proposal"
              element={<CreateNewPurposal />}
            />
            <Route
              path="/dashboard/create_order"
              element={<CreateNewOrder />}
            />
            <Route path="/dashboard/create-sip" element={<CreateNewSIP />} />
            <Route path="/dashboard/final_create" element={<FinalCreate />} />
            <Route path="/dashboard/sell" element={<Sell />} />
            <Route path="/dashboard/create_sell" element={<CreateNewSell />} />
            <Route
              path="/dashboard/final_create_sell"
              element={<FinalCreateSell />}
            />
            <Route
              path="/dashboard/final-create-sip/:clientId"
              element={<FinalCreateSip />}
            />
            <Route path="/dashboard/profile" element={<PersonalDetails />} />
            <Route
              path="/dashboard/aboutbatukpartener"
              element={<AboutBatukPartener />}
            />
            <Route
              path="/dashboard/personaldetails"
              element={<PersonalDetails />}
            />
            <Route path="/dashboard/earning" element={<Earning />} />
            <Route path="/dashboard/sales-reports" element={<SalesReports />} />
            <Route path="/dashboard/upload-file" element={<UploadFile />} />
            <Route path="/dashboard/spot-gold" element={<SpotGold />} />
            <Route path="/dashboard/product/:clientId" element={<Product />} />
            <Route
              path="/dashboard/product/:clientId/:id"
              element={<ProductDetails />}
            />
            <Route
              path="/dashboard/product/:clientId/:id/address-details"
              element={<AddressDetails />}
            />
            <Route path="/dashboard/reports" />
            <Route path="/dashboard/sip-mining" element={<SipMining />} />
            <Route
              path="/dashboard/sip-business-report"
              element={<SipBusinessReport />}
            />
            <Route
              path="/dashboard/file-import-history"
              element={<FileImportHistory />}
            />
            <Route
              path="/dashboard/compliance-report"
              element={<ComplianceReport />}
            />
            <Route
              path="/dashboard/inactive-clients"
              element={<InactiveClients />}
            />
            <Route path="/dashboard/missing-sip" element={<MissingSip />} />
            {/* </Route> */}
            <Route
              path="/dashboard/reset-password"
              element={<ResetPassword />}
            />
          </Route>
        )}

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
