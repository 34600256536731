import React, { useState, useEffect } from "react";
import axios from "axios";
import GOLD from "../../assets/Icons/gold_shield.png";
import SILVER from "../../assets/Icons/silver_shield.png";
import "../../assets/css/final_create.css";
import { baseUrl } from "../../utils/baseUrl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";

const FinalCreateSell = () => {
  const clientData = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const Token = sessionStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  let client = location.state?.client;

  const [selectedMetal, setSelectedMetal] = useState("gold");
  const [isGoldSelected, setIsGoldSelected] = useState(true);
  const [isRupeeSelected, setIsRupeeSelected] = useState(true);
  const [rupeeValue, setRupeeValue] = useState("");
  const [gramValue, setGramValue] = useState("");
  const [goldPrice, setGoldPrice] = useState("");
  // const [goldSellPrice, setSellGoldPrice] = useState("");
  const [silverPrice, setSilverPrice] = useState("");
  const [clientId, setClientId] = useState(client._id);
  const [timer, setTimer] = useState(180);
  const [detailsData, setDetailsData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (clientData && clientData.client && clientData.client._id) {
      setClientId(clientData.client._id);
    }
  }, [clientData]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          refreshPrices();
          return 180;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const refreshPrices = () => {
    if (isGoldSelected) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  };

  const getGoldLive = async () => {
    try {
      const url = `${baseUrl}/sellliveprice/gold`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      setGoldPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getSilverLive = async () => {
    try {
      const url = `${baseUrl}/sellliveprice/silver`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      setSilverPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/fd/getPort/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const fdData = response.data.data[0];
      setDetailsData(fdData);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const createPurposal = async () => {
    if (error) {
      return;
    } else {
      setLoading(true);
    }
    let enteredAmount = isRupeeSelected ? rupeeValue : gramValue;
    setError("");

    if (enteredAmount <= 0) {
      setError("The amount must be greater than zero.");
      return;
    }

    const availableBalance = isGoldSelected
      ? detailsData.augmont?.gold?.balQuantity
      : detailsData.augmont?.silver?.balQuantity;

    if (!availableBalance || availableBalance <= 0) {
      setError(
        `You don't have enough ${
          isGoldSelected ? "gold" : "silver"
        } in your locker.`
      );
      return;
    }

    if (!isRupeeSelected && gramValue > availableBalance) {
      setError(
        `You have only ${availableBalance} gm of ${
          isGoldSelected ? "gold" : "silver"
        } available in your locker.`
      );
      return;
    }

    if (isRupeeSelected) {
      const calculatedGrams = (
        rupeeValue / (isGoldSelected ? goldPrice : silverPrice)
      ).toFixed(4);
      if (calculatedGrams > availableBalance) {
        setError(
          `You have only ${availableBalance} gm of ${
            isGoldSelected ? "gold" : "silver"
          } available in your locker. You can't convert ₹${rupeeValue} to more than ${availableBalance} gm.`
        );
        return;
      }
    }

    let data = {
      metalType: selectedMetal,
    };

    if (isRupeeSelected && rupeeValue) {
      data.amount = rupeeValue;
    } else if (!isRupeeSelected && gramValue) {
      data.quantity = gramValue;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/partnerPortal/sell/${clientId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: JSON.stringify(data),
    };

    try {
      const response = await axios.request(config);
      const result = response.data;

      if (result.error === false) {
        setLoading(false);
        Swal.fire({
          title: "Thank You!",
          text: "The proposal was created successfully!",
          icon: "success",
        });
        navigate("/dashboard/sell");
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: result.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error while creating the proposal. Please try again.",
      });
    }
  };

  const handleMetalButtonClick = (isGold) => {
    setIsGoldSelected(isGold);
    setSelectedMetal(isGold ? "gold" : "silver");
    setTimer(180);
    if (isGold) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  };

  useEffect(() => {
    handleMetalButtonClick(true);
  }, []);

  const handleUnitButtonClick = (isRupee) => {
    setIsRupeeSelected(isRupee);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setError("");
    const availableBalance = isGoldSelected
      ? detailsData.augmont?.gold?.balQuantity
      : detailsData.augmont?.silver?.balQuantity;

    if (!isRupeeSelected) {
      setGramValue(value);

      if (isNaN(value) || value <= 0) {
        setError("The value must be a positive number greater than zero.");
      }
    } else {
      setRupeeValue(value);
      if (isNaN(value) || value < 1) {
        setError("The value must be a positive number greater than 1.");
      }
    }

    if (!isRupeeSelected && parseFloat(value) > availableBalance) {
      setError(
        `You have only ${availableBalance} gm of ${
          isGoldSelected ? "gold" : "silver"
        } available in your locker.`
      );
    }

    if (isRupeeSelected) {
      const calculatedGrams = parseFloat(
        (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
      );

      if (calculatedGrams > availableBalance) {
        setError(
          `You have only ${availableBalance} gm of ${
            isGoldSelected ? "gold" : "silver"
          } available in your locker. You can't convert ₹${value} to more than ${availableBalance} gm.`
        );
        // return;
      }
    }

    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (value * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2)
          : ""
      );
    }
  };

  const handleAmountButtonClick = (amount) => {
    const value = amount.replace("₹", "");
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (value / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (value * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2)
          : ""
      );
    }
  };

  const metal = isGoldSelected ? "GOLD" : "SILVER";
  const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
  const metalImage = isGoldSelected ? GOLD : SILVER;
  const unit = isRupeeSelected ? "₹" : "gm";
  const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className={loading ? "blurred" : ""}>
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <header className="header-container">
        <div className="button-group">
          <button
            className={`final-btn gold-btn gl ${
              isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(true)}
          >
            Sell Gold
          </button>
          <button
            className={`final-btn silver-btn gl ${
              !isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(false)}
          >
            Sell Silver
          </button>
        </div>
      </header>
      <div className="bordered-div">
        <div className="inner-container">
          <div className="inner-div">
            <button
              className="btn card-btn"
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {metal}
            </button>
            <h3>{`${metal} PRICE`}</h3>
            <div className="paragst">
              <p className="medium-text">{metalPrice}</p>
            </div>
            <p className="mini-text">{metalTag}</p>
          </div>
          <div className="inner-div hide-text">Div 2</div>
          <div className="inner-div">
            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={metalImage}
                alt={`${metal} image`}
                style={{ width: "110px", marginBottom: "1rem" }}
              />
            </div>

            <div className="timer">
              <p className="mini-text">{`${metal} rate expires in :`}</p>
              <p className="time">{formatTime(timer)}</p>
            </div>
          </div>
        </div>
        <div className="button-row-bottom">
          <div className="irg">
            <button
              className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(true)}
            >
              In Rupee
            </button>
            <div className="vl"></div>
            <button
              className={`final-btn ig-btn ${!isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(false)}
            >
              In Grams
            </button>
          </div>
        </div>
        <div className="input-container">
          <div>
            <div
              className={` ${error ? "error-border" : "input-border"}`}
              style={{ width: "100%" }}
            >
              <div className="input-1">
                <span className="rupee">{unit}</span>
              </div>
              <input
                type="text"
                value={isRupeeSelected ? rupeeValue : gramValue}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              <div className="input-2" style={{ width: "100%" }}>
                <span className="rupee">
                  {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
                </span>
              </div>
            </div>
            {/* Display error message */}
            {error && (
              <p
                className="error-message"
                style={{ color: "red", fontSize: "14px" }}
              >
                {error}
              </p>
            )}
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                {isGoldSelected ? (
                  <p>
                    Gold In Locker: {detailsData.augmont?.gold?.balQuantity} gm
                  </p>
                ) : (
                  <p>
                    Silver In Locker: {detailsData.augmont?.silver?.balQuantity}{" "}
                    gm
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="button-container grid">
          {["100", "200", "300", "400", "500"].map((amount) => (
            <button
              className="btn card-btn"
              key={amount}
              onClick={() => handleAmountButtonClick(amount)}
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
            </button>
          ))}
        </div>
        <div className="button-container">
          <button
            type="button"
            className="btn card-btn submit-btn"
            onClick={createPurposal}
            style={{
              backgroundColor: "#4a2a7d",
              color: "white",
              width: "10rem",
              marginBottom: "0px",
            }}
          >
            Create Sell Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalCreateSell;
