import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Form, Row } from "react-bootstrap";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { Button } from "@mui/material";
import ProfileLogo from "../assets/Icons/ProfileIcon.png";

const PersonalDetails = () => {
  const token = sessionStorage.getItem("token");
  const [isEditing, setIsEditing] = useState(false);

  const [country, setCountryid] = useState(101);
  const [state, setStateid] = useState(0);
  const [city, setCityid] = useState(0);

  const handleCountryChange = (country) => {
    setCountryid(country.id);
    setStateid(0);
    setCityid(0);
  };

  const handleStateChange = (state) => {
    setStateid(state.id);
    profileData.state = state.name;
    setCityid(0);
  };

  const handleCityChange = (city) => {
    setCityid(city.id);
    profileData.city = city.name;
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    city: "",
    state: "",
    Address: "",
    panNo: "",
    aadharNo: "",
    accHoldername: "",
    accNo: "",
    Ifsccode: "",
    dob: "",
    userName: "",
  });

  const getProfileData = async () => {
    try {
      const url = `${baseUrl}/profile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const profile = response.data.data[0];
      setProfileData(profile);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${baseUrl}/profileUpdate`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.put(url, profileData, { headers });
      toast.success("Profile updated successfully");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Profile
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-8 col-md-10 col-sm-12" style={{ width: "100%" }}>
          <div className="card-body">
            <div className="row">
              <div
                className="col-lg-4 text-center"
                style={{ marginBottom: "1rem" }}
              >
                <div
                  className=""
                  style={{
                    border: "1px solid",
                    borderRadius: "8px",
                    padding: "1rem",
                  }}
                >
                  <img
                    src={ProfileLogo}
                    alt="User Avatar"
                    className="img-fluid rounded-circle mb-3"
                    style={{ width: "10rem" }}
                  />
                  <div className="profile-div">
                    <h5>{profileData.name}</h5>
                    <h6>Username - {profileData.userName}</h6>
                    <h6>Register Type - {profileData.registerType}</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 profile-bottom">
                {!isEditing ? (
                  <div
                    className="profile-show"
                    style={{
                      padding: "1rem",
                      border: "1px solid",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="btn btn-primary"
                        onClick={() => setIsEditing(true)}
                        style={{
                          textTransform: "capitalize",
                          height: "2.5rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Update Profile
                      </Button>
                    </div>
                    <h4
                      className="mb-4 text-color"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#dcdaff",
                      }}
                    >
                      Personal Details
                    </h4>
                    <div className="profile-text">
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.name}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formDOB">
                            <Form.Label>Date of Birth</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.dob}
                            </h5>
                          </Form.Group>
                        </Col>

                        <Col md={4}>
                          <Form.Group controlId="formPhone">
                            <Form.Label>Mobile No.</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.mobileNo}
                            </h5>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="formAadhar">
                            <Form.Label>Aadhar No.</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.aadharNo}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.email}
                            </h5>
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4
                        className="mb-4 text-color "
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#dcdaff",
                        }}
                      >
                        Address Details
                      </h4>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.Address}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formState">
                            <Form.Label>State</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.state}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.city}
                            </h5>
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4
                        className="mb-4 text-color"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#dcdaff",
                        }}
                      >
                        Bank Detail
                      </h4>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="accountHolderName">
                            <Form.Label>Account Holder's Name</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.accHoldername}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="accountNo">
                            <Form.Label>Account No.</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.accNo}
                            </h5>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formPanNumber">
                            <Form.Label>PAN No.</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.panNo}
                            </h5>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="ifscCode">
                            <Form.Label>IFSC Code</Form.Label>
                            <h5 style={{ fontSize: "16px" }}>
                              {profileData.Ifsccode}
                            </h5>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  <div
                    className="input-group"
                    style={{
                      padding: "1rem",
                      border: "1px solid",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="btn btn-primary"
                        onClick={() => setIsEditing(false)}
                        style={{
                          textTransform: "capitalize",
                          height: "2.5rem",
                          marginBottom: "1rem",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <h4
                      className="mb-4 text-color"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#dcdaff",
                      }}
                    >
                      Personal Details
                    </h4>
                    <Form onSubmit={handleSubmit}>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Full Name"
                              name="name"
                              value={profileData.name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formDOB">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="Enter Date of Birth"
                              name="dob"
                              value={profileData.dob}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formPhone">
                            <Form.Label>Mobile No.</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Mobile No"
                              name="mobileNo"
                              value={profileData.mobileNo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="formAadhar">
                            <Form.Label>Aadhar No.</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Aadhar No."
                              name="aadharNo"
                              value={profileData.aadharNo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                              value={profileData.email}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4
                        className="mb-4 text-color "
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#dcdaff",
                        }}
                      >
                        Address Details
                      </h4>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Your Address"
                              name="Address"
                              value={profileData.Address}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formState">
                            <Form.Label>State</Form.Label>
                            <StateSelect
                              placeHolder={profileData.state}
                              type="text"
                              name="state"
                              countryid={country}
                              onChange={(state) => handleStateChange(state)}
                              value={profileData.state}
                              className="form-control"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <CitySelect
                              placeHolder={profileData.city}
                              type="text"
                              name="city"
                              countryid={country}
                              stateid={state}
                              onChange={(city) => handleCityChange(city)}
                              value={profileData.city}
                              className="form-control"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <h4
                        className="mb-4 text-color"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#dcdaff",
                        }}
                      >
                        Bank Detail
                      </h4>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="accountHolderName">
                            <Form.Label>Account Holder's Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Account Holder's Name"
                              name="accHoldername"
                              value={profileData.accHoldername}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="accountNo">
                            <Form.Label>Account No.</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Account No."
                              name="accNo"
                              value={profileData.accNo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group controlId="formPanNumber">
                            <Form.Label>PAN No.</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter PAN No."
                              name="panNo"
                              value={profileData.panNo}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="row-bottom">
                        <Col md={4}>
                          <Form.Group controlId="ifscCode">
                            <Form.Label>IFSC Code</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter IFSC Code"
                              name="Ifsccode"
                              value={profileData.Ifsccode}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="text-end">
                        <Button
                          type="submit"
                          variant="contained"
                          className="btn btn-primary"
                          style={{
                            textTransform: "capitalize",
                            height: "2.5rem",
                          }}
                        >
                          Update
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
