import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Card, Row, Col, Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { mkConfig, generateCsv, download } from "export-to-csv";
import moment from "moment/moment";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TotalAmount from "../assets/Icons/Untitled design (2).png";
import TotalGoldAmount from "../assets/Icons/Untitled design (3).png";
import TotalSilverAmount from "../assets/Icons/Untitled design (5).png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";

const Earning = () => {
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [transactionApiData, setTransactionApiData] = useState([]);
  const [TotalAmountData, setTotalAmountData] = useState({});

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("client_id.name", {
      header: "Investor Name",
      size: 30,
    }),
    columnHelper.accessor("metal_type", {
      header: "Metal Type",
      size: 30,
    }),
    columnHelper.accessor("commission", {
      header: "Earning",
      size: 40,
      Cell: (cell) => "₹" + cell.row.original.commission,
    }),
    columnHelper.accessor("preTaxAmount", {
      header: "PreTaxAmount",
      size: 40,
      Cell: ({ cell }) =>
        cell.row.original.preTaxAmount != null
          ? "₹" + cell.row.original.preTaxAmount
          : "N/A",
    }),
    columnHelper.accessor("gst", {
      header: "Tax Amount",
      size: 40,
      Cell: (cell) => "₹" + cell.row.original.gst,
    }),
    columnHelper.accessor("total_amount", {
      header: "Total Amount",
      size: 40,
      Cell: (cell) => "₹" + cell.row.original.total_amount,
    }),
    columnHelper.accessor((row) => moment(row.createdAt).format("DD-MM-YYYY"), {
      header: "Date",
      size: 40,
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = (rows) => {
    const rowData = rows.map((row) => ({
      clientName: row.original.client_id.name,
      metalType: row.original.metal_type,
      earning: row.original.commission,
      totalAmount: row.original.total_amount,
      date: new Date(row.original.createdAt).toISOString().split("T")[0],
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      clientName: row.original.client_id.name,
      metalType: row.original.metal_type,
      earning: row.original.commission,
      totalAmount: row.original.total_amount,
      date: new Date(row.original.createdAt).toISOString().split("T")[0],
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: transactionApiData,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const fetchTotalAmountData = async (startDate, endDate) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${baseUrl}/getPartnerInvestments`, {
        headers,
        params: {
          startDate,
          endDate,
        },
      });

      if (response.data.error == false) {
        setLoading(false);
        setTotalAmountData(response.data.data);
        setTransactionApiData(response.data.data.allTransactions);
      } else {
        toast.error("No data found between given date range.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching total amount data:", error);
    }
  };

  useEffect(() => {
    fetchTotalAmountData();
  }, []);

  // ------------------------end---------------------------------

  const [filterType, setFilterType] = useState("Today");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [customDate, setCustomDate] = useState({ startDate: "", endDate: "" });
  const dropdownRef = useRef(null);

  const handleFilterChange = async (e) => {
    const selectedFilter = e.target.value;
    setFilterType(selectedFilter);

    let startDate = "";
    let endDate = moment().format("YYYY-MM-DD");
    if (selectedFilter === "30Days") {
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
    } else if (selectedFilter === "Custom") {
      setIsDropdownOpen(true);
      return;
    }
    await fetchTotalAmountData(startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setCustomDate({ ...customDate, [name]: value });
  };

  const handleSubmit = async () => {
    const { startDate, endDate } = customDate;

    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates!");
      return;
    }
    setIsDropdownOpen(false);
    await fetchTotalAmountData(startDate, endDate);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "61%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Earning Report
            </h2>
          </Col>
          <div xs={6} className="filter-data-drop">
            <div className="dropdown-wrapper" ref={dropdownRef}>
              <select
                className="filter-dropdown"
                value={filterType}
                onChange={handleFilterChange}
                onClick={() => setIsDropdownOpen(true)}
              >
                <option value="30Days">30 Days</option>
                <option value="Custom">Custom Date</option>
              </select>

              {isDropdownOpen && filterType === "Custom" && (
                <div className="custom-dropdown">
                  <input
                    type="date"
                    name="startDate"
                    value={customDate.startDate}
                    onChange={handleDateChange}
                    className="date-input"
                  />
                  <input
                    type="date"
                    name="endDate"
                    value={customDate.endDate}
                    onChange={handleDateChange}
                    className="date-input"
                  />
                  <button
                    onClick={handleSubmit}
                    className="submit-button"
                    disabled={!customDate.startDate || !customDate.endDate}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Container fluid>
        <Row className="mb-4">
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
              <div className="d-flex">
                <img
                  src={TotalAmount}
                  alt="Total Amount"
                  width="100"
                  className="me-3"
                />
                <div>
                  <Typography variant="h6">Total Earning</Typography>
                  <Typography variant="h6">
                    ₹ {TotalAmountData.totalEarning}
                  </Typography>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
              <div className="d-flex">
                <img
                  src={TotalGoldAmount}
                  alt="Total Gold Amount"
                  width="100"
                  className="me-3"
                />
                <div>
                  <Typography variant="h6">Gold Amount</Typography>
                  <Typography variant="h6">
                    ₹{TotalAmountData.totalGoldAmount}
                  </Typography>
                </div>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
              <div className="d-flex">
                <img
                  src={TotalSilverAmount}
                  alt="Total Silver Amount"
                  width="100"
                  className="me-3"
                />
                <div>
                  <Typography variant="h6">Silver Amount</Typography>
                  <Typography variant="h6">
                    ₹{TotalAmountData.totalSilverAmount}
                  </Typography>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Earning;
