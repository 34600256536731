import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Row, Col, Container } from "react-bootstrap";
import { Grid, IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
} from "@mui/icons-material";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";

const Employee = () => {
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [mobile, setMobile] = useState("");
  const [newEmployeeName, setNewEmployeeName] = useState("");
  const [newEmployeeEmail, setNewEmployeeEmail] = useState("");
  const [employees, setEmployees] = useState([]);
  const [NewMobile, setNewMobile] = useState("");
  const [NewDesign, setNewDesign] = useState("");
  const [progressData, setprogressData] = useState();

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [designationError, setDesignationError] = useState("");

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    GetInprogressClient();
  }, []);

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      size: 30,
    }),
    columnHelper.accessor("Email", {
      header: "Email",
      size: 30,
    }),
    columnHelper.accessor("PhoneNo", {
      header: "Mobile No",
      size: 30,
    }),
    columnHelper.accessor("Designation", {
      header: "Designation",
      size: 30,
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const rowData = employeeList.map((employee) => ({
      name: employee.name,
      email: employee.Email,
      phoneNo: employee.PhoneNo,
      designation: employee.Designation,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      name: row.original.name,
      email: row.original.Email,
      phoneNo: row.original.PhoneNo,
      designation: row.original.Designation,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: employeeList,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ row }) => [
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>
        <IconButton onClick={() => deleteEmployee(row.original._id)}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>,
    ],

    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const fetchEmployees = async () => {
    try {
      const url = `${baseUrl}/Employee_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response?.data?.data;

      setLoading(false);
      setEmployeeList(employees);
      setLoading(false);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateEmployee = async (employeeId) => {
    try {
      const url = `${baseUrl}/employee_update/${employeeId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: Name,
        Email: email,
        Designation: designation,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.error == true) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        setEmployees({
          name: "",
          email: "",
          Designation: "",
        });
      }
      fetchEmployees();
    } catch (error) {
      console.error("Error connecting to API", error);
      console.error("Error connecting to API", error);
    }
  };

  const GetInprogressClient = async () => {
    try {
      const url = `${baseUrl}/getProposal`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      setprogressData(response.data.data.PendingProposals);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const deleteEmployee = async (employeeId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4A2A7D",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // =============================================================================

        try {
          const url = `${baseUrl}/employee_delete/${employeeId}`;
          const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          };
          const response = await axios.delete(url, { headers });
          // const employees = response.data.data;
          GetInprogressClient();
          fetchEmployees();
        } catch (error) {
          console.error("Error connecting to API", error);
        }

        // =============================================================================
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Employee has been deleted.",
          icon: "success",

          customClass: {
            confirmButton: "my-custom-button-class",
          },
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your Employee is safe :)",
          icon: "error",
        });
      }
    });
  };

  const CreateNewEmp = async () => {
    try {
      const url = `${baseUrl}/employee_create`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: newEmployeeName,
        Email: newEmployeeEmail,
        PhoneNo: NewMobile,
        Designation: NewDesign,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;

      if (data.error == false) {
        toast.success(data.message);
        fetchEmployees();
        setNewEmployeeName("");
        setNewEmployeeEmail("");
        setNewMobile("");
        setNewDesign("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleNewEmployeeMobileChange = (e) => {
    setNewMobile(e.target.value);
  };

  const handlenewDesignantion = (e) => {
    setNewDesign(e.target.value);
  };

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    setEmail(employee.Email);
    setDesignation(employee.Designation);
    setMobile(employee.PhoneNo);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };

  const handleSave = (employee) => {
    updateEmployee(employee._id);
    closeEditModal();
  };

  const handleNewEmployeeNameChange = (e) => {
    setNewEmployeeName(e.target.value);
  };

  const handleNewEmployeeEmailChange = (e) => {
    setNewEmployeeEmail(e.target.value);
  };

  const handleCreateEmployee = () => {
    let valid = true;

    if (newEmployeeName.trim() === "") {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    if (newEmployeeEmail.trim() === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(newEmployeeEmail)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (NewMobile.trim() === "") {
      setMobileError("Mobile number is required");
      valid = false;
    } else if (!/^\d{10}$/.test(NewMobile.trim())) {
      setMobileError("Mobile number should be 10 digits");
      valid = false;
    } else {
      setMobileError("");
    }

    if (NewDesign.trim() === "") {
      setDesignationError("Designation is required");
      valid = false;
    } else {
      setDesignationError("");
    }

    if (valid) {
      CreateNewEmp();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="dashboard section">
          <ToastContainer />
          <div className="dashboard section">
            <div className="row">
              <div
                className="col-md-12 ms-2 mt-2"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col
                  xs={6}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2
                    className="TABLE_HEADING"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                    }}
                  >
                    Add Employee
                  </h2>
                </Col>
              </div>
            </div>
            <hr />
            <Card className="mb-4" style={{ width: "100%" }}>
              <Container fluid>
                <Row className="mb-4">
                  <div xs={12} style={{ paddingTop: "1.5rem" }}>
                    <Box
                      component="form"
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "0.5rem",
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="name"
                            label="Name"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            value={newEmployeeName}
                            onChange={handleNewEmployeeNameChange}
                            error={!!nameError}
                            helperText={nameError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="email"
                            label="Email"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="email"
                            value={newEmployeeEmail}
                            onChange={handleNewEmployeeEmailChange}
                            error={!!emailError}
                            helperText={emailError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="mobile"
                            label="Mobile No."
                            required
                            fullWidth
                            size="small"
                            inputProps={{ maxLength: 10 }}
                            variant="outlined"
                            type="text"
                            value={NewMobile}
                            onChange={handleNewEmployeeMobileChange}
                            error={!!mobileError}
                            helperText={mobileError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="designation"
                            label="Designation"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            value={NewDesign}
                            onChange={handlenewDesignantion}
                            error={!!designationError}
                            helperText={designationError}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Button
                            variant="contained"
                            onClick={handleCreateEmployee}
                            className="button-width"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Row>
              </Container>
            </Card>
            <Row>
              <Col xs={12}>
                <h2 className="TABLE_HEADING"></h2>
                {loading ? (
                  <TableSkeleton />
                ) : (
                  <MaterialReactTable table={table} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* Edit Employee Modal */}
      {selectedEmployee && (
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Employee"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
            },
          }}
        >
          <h3 className="text-center fw-bold form_header">Edit Employee</h3>
          <form>
            {/* <div className="form-group p-2">
              <label className="label-color">User ID</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={selectedEmployee._id}
                readOnly
              />
            </div> */}

            <div className="form-group p-2">
              <label className="label-color">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group p-2">
              <label className="label-color">Email</label>
              <input
                type="email"
                className="form-control"
                name="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="form-group p-2">
              <label className="label-color">Designation</label>
              <input
                type="text"
                className="form-control"
                name="designation"
                value={designation}
                onChange={handleDesignationChange}
              />
            </div>

            <Button
              variant="contained"
              type="button"
              className="btn btn-secondary mt-2"
              style={{ float: "left" }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="button"
              className="btn btn-primary mt-2"
              style={{ float: "right" }}
              onClick={() => handleSave(selectedEmployee)}
            >
              Update
            </Button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Employee;
