import React, { useState, useEffect } from "react";
import axios from "axios";
import GOLD from "../assets/Icons/gold_shield.png";
import SILVER from "../assets/Icons/silver_shield.png";
import "../assets/css/final_create.css";
import { baseUrl } from "../utils/baseUrl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../components/Loader";

const FinalCreate = () => {
  const clientData = useSelector((state) => state);
  console.log(clientData, "client data");
  const [loading, setLoading] = useState(false);
  const Token = sessionStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  let client = location.state?.client;
  console.log(client, "clienttttttttttt");

  const [selectedMetal, setSelectedMetal] = useState("gold");
  const [isGoldSelected, setIsGoldSelected] = useState(true);
  const [isRupeeSelected, setIsRupeeSelected] = useState(true);
  const [rupeeValue, setRupeeValue] = useState("");
  const [gramValue, setGramValue] = useState("");
  const [goldPrice, setGoldPrice] = useState("");
  const [silverPrice, setSilverPrice] = useState("");
  const [clientId, setClientId] = useState(client._id);
  const [timer, setTimer] = useState(180);

  useEffect(() => {
    if (isGoldSelected) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  }, [isGoldSelected]);

  useEffect(() => {
    if (clientData && clientData.client && clientData.client._id) {
      setClientId(clientData.client._id);
    }
  }, [clientData]);

  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(countdown);
    }
    return () => clearInterval(countdown);
  }, [timer]);

  const getGoldLive = async () => {
    try {
      const url = `${baseUrl}/liveprice/gold`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      console.log(response, "goldddddddddddddddd");
      setGoldPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const getSilverLive = async () => {
    try {
      const url = `${baseUrl}/liveprice/silver`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const liveValue = response.data.data;
      console.log(response, "silverrrrrrrrrrrrrrrr");
      setSilverPrice(liveValue);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const createPurposal = async () => {
    setLoading(true);
    const weight = isRupeeSelected
      ? (rupeeValue / (isGoldSelected ? goldPrice : silverPrice)).toFixed(4)
      : gramValue;
    const amount = isRupeeSelected
      ? rupeeValue
      : (gramValue * (isGoldSelected ? goldPrice : silverPrice)).toFixed(2);

    let data = {
      metal_type: selectedMetal,
    };

    if (isRupeeSelected) {
      data.amount = amount;
    } else {
      data.quantity = weight;
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/proposal_create/${clientId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .then((d) => {
        if (d.message === "The proposal was created successfully") {
          setLoading(false);
          Swal.fire({
            title: "Thank You!",
            text: "The proposal was created successfully!",
            icon: "success",
          });
          navigate("/dashboard/proposal");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: d.message,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleMetalButtonClick = (isGold) => {
    setIsGoldSelected(isGold);
    setSelectedMetal(isGold ? "gold" : "silver");
    setTimer(180); // Reset the timer to 3 minutes
    if (isGold) {
      getGoldLive();
    } else {
      getSilverLive();
    }
  };

  const handleUnitButtonClick = (isRupee) => {
    setIsRupeeSelected(isRupee);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const gstRate = 1.03;
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (
              value /
              gstRate /
              (isGoldSelected ? goldPrice : silverPrice)
            ).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (
              value *
              (isGoldSelected ? goldPrice : silverPrice) *
              gstRate
            ).toFixed(2)
          : ""
      );
    }
  };

  const handleAmountButtonClick = (amount) => {
    const value = amount.replace("₹", "");
    const gstRate = 1.03;
    if (isRupeeSelected) {
      setRupeeValue(value);
      setGramValue(
        value
          ? (
              value /
              gstRate /
              (isGoldSelected ? goldPrice : silverPrice)
            ).toFixed(4)
          : ""
      );
    } else {
      setGramValue(value);
      setRupeeValue(
        value
          ? (
              value *
              (isGoldSelected ? goldPrice : silverPrice) *
              gstRate
            ).toFixed(2)
          : ""
      );
    }
  };

  const metal = isGoldSelected ? "GOLD" : "SILVER";
  const metalPrice = isGoldSelected ? `₹${goldPrice}/gm` : `₹${silverPrice}/gm`;
  const metalImage = isGoldSelected ? GOLD : SILVER;
  const unit = isRupeeSelected ? "₹" : "gm";
  const metalTag = isGoldSelected ? "24K 99.9% Pure Gold" : "999.9 Purity";

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className={loading ? "blurred" : ""}>
      {loading && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <header className="header-container">
        <div className="button-group">
          <button
            className={`final-btn gold-btn gl ${
              isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(true)}
          >
            Gold
          </button>
          <button
            className={`final-btn silver-btn gl ${
              !isGoldSelected ? "active" : ""
            }`}
            onClick={() => handleMetalButtonClick(false)}
          >
            Silver
          </button>
        </div>
      </header>
      <div className="bordered-div">
        <div className="inner-container">
          <div className="inner-div">
            <button
              className="btn card-btn"
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {metal}
            </button>
            <h3>{`${metal} PRICE`}</h3>
            <div className="paragst">
              <p className="medium-text">{metalPrice}</p>
              <p className="gst">+3% GST</p>
            </div>
            <p className="mini-text">{metalTag}</p>
          </div>
          <div className="inner-div hide-text">Div 2</div>
          <div className="inner-div">
            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={metalImage}
                alt={`${metal} image`}
                style={{ width: "110px", marginBottom: "1rem" }}
              />
            </div>

            <div className="timer">
              <p className="mini-text">{`${metal} rate expires in :`}</p>
              <p className="time">{formatTime(timer)}</p>
            </div>
          </div>
        </div>
        <div className="button-row-bottom">
          <div className="irg">
            <button
              className={`final-btn ir-btn ${isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(true)}
            >
              In Rupee
            </button>
            <div className="vl"></div>
            <button
              className={`final-btn ig-btn ${!isRupeeSelected ? "active" : ""}`}
              onClick={() => handleUnitButtonClick(false)}
            >
              In Grams
            </button>
          </div>
        </div>
        <div className="input-container">
          <div className="input-border">
            <div className="input-1">
              <span className="rupee">{unit}</span>
            </div>
            <input
              type="text"
              value={isRupeeSelected ? rupeeValue : gramValue}
              onChange={handleInputChange}
              style={{ width: "100%" }}
            />
            <div className="input-2" style={{ width: "100%" }}>
              <span className="rupee">
                {isRupeeSelected ? `${gramValue}gm` : `₹ ${rupeeValue}`}
              </span>
            </div>
          </div>
        </div>
        <div className="button-container grid">
          {["100", "200", "300", "400", "500"].map((amount) => (
            <button
              className="btn card-btn"
              key={amount}
              onClick={() => handleAmountButtonClick(amount)}
              style={{ backgroundColor: "#4a2a7d", color: "white" }}
            >
              {isRupeeSelected ? `₹ ${amount}` : `${amount}gm`}
            </button>
          ))}
        </div>
        <div className="button-container">
          <button
            type="button"
            className="btn card-btn submit-btn"
            onClick={createPurposal}
            style={{
              backgroundColor: "#4a2a7d",
              color: "white",
              width: "10rem",
              marginBottom: "0px",
            }}
          >
            Create Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalCreate;
