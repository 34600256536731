import Home from "../assets/Icons/Home.png";
import HomeHover from "../assets/Icons/Home 2.png";
import Earning from "../assets/Icons/3d earnings.png";
import EarningHover from "../assets/Icons/3d earnings 3.png";
import Client from "../assets/Icons/Client lists.png";
import ClientHover from "../assets/Icons/Client Lists 2.png";
import Employees from "../assets/Icons/Employees.png";
import EmployeesHover from "../assets/Icons/Employees 2.png";
import Sip from "../assets/Icons/Sip.png";
import SIPHover from "../assets/Icons/Sip 2.png";
// import Fd from "../assets/Icons/Gold FD.png";
// import FDHover from "../assets/Icons/Gold FD 2.png";
import Proposal from "../assets/Icons/Proposal.png";
import SellHover from "../assets/Icons/Sell s.png";
import Sell from "../assets/Icons/Sell.png";
import ProposalHover from "../assets/Icons/Proposal 2.png";
// import Reports from "../assets/reportsIcons/Reports.png";
// import ReportsHover from "../assets/reportsIcons/Reports 2.png";
import Sales from "../assets/reportsIcons/Sale.png";
import SalesHover from "../assets/reportsIcons/Sale 2.png";
import ImpFiles from "../assets/Icons/Bulk import.png";
import ImpFilesHover from "../assets/Icons/Bulk import 2.png";
import SpotGold from "../assets/Icons/Spot Gold (1).png";
import SpotGoldHover from "../assets/Icons/Spot Gold 2.png";
import SIPMining from "../assets/reportsIcons/Sip mining 2.png";
import SIPMiningHover from "../assets/reportsIcons/Sip mining.png";
import SIPBusiness from "../assets/reportsIcons/SIP Buisness Report.png";
import SIPBusinessHover from "../assets/reportsIcons/SIP Buisness Report 2.png";
import FileImportHistory from "../assets/reportsIcons/File Import History.png";
import FileImportHistoryHover from "../assets/reportsIcons/File Import History 2.png";
import ComplianceReport from "../assets/reportsIcons/Compliance Report 2.png";
import ComplianceReportHover from "../assets/reportsIcons/Compliance Report.png";
import InactiveClients from "../assets/reportsIcons/Inactive Client.png";
import InactiveClientsHover from "../assets/reportsIcons/Inactive Client 2.png";
import MissingSIP from "../assets/reportsIcons/Missing SIP.png";
import MissingSIPHover from "../assets/reportsIcons/Missing SIP 2.png";

const navigationData = [
  {
    id: "home",
    name: "Dashboard",
    route: "/dashboard",
    icon: Home,
    hoverIcon: HomeHover,
  },
  {
    id: "clients",
    name: "Investors",
    route: "/dashboard/client_table",
    icon: Client,
    hoverIcon: ClientHover,
  },
  {
    id: "employees",
    name: "Employees",
    route: "/dashboard/employee_table",
    icon: Employees,
    hoverIcon: EmployeesHover,
  },
  {
    id: "sip",
    name: "SIP",
    route: "/dashboard/sip",
    icon: Sip,
    hoverIcon: SIPHover,
  },
  //   {
  //     id: "goldfd",
  //     name: "Gold FD",
  //     route: "/dashboard/gold-fd",
  //     icon: Fd,
  //     hoverIcon: FDHover,
  //   },
  //   {
  //     id: "tracker",
  //     name: "Tracker",
  //     route: "/tracker",
  //     icon: Reports,
  //     hoverIcon: ReportsHover,
  //   },
  {
    id: "proposal",
    name: "Buy Gold / Silver",
    route: "/dashboard/proposal",
    icon: Proposal,
    hoverIcon: ProposalHover,
  },
  {
    id: "sell",
    name: "Sell Gold / Silver",
    route: "/dashboard/sell",
    icon: Sell,
    hoverIcon: SellHover,
  },
  {
    id: "spotgold",
    name: "Jewellery",
    route: "/dashboard/spot-gold",
    icon: SpotGold,
    hoverIcon: SpotGoldHover,
  },
  {
    id: "earning",
    name: "Earnings",
    route: "/dashboard/earning",
    icon: Earning,
    hoverIcon: EarningHover,
  },
  {
    id: "salesReports",
    name: "Sales",
    route: "/dashboard/sales-reports",
    icon: Sales,
    hoverIcon: SalesHover,
  },
  {
    id: "impfiles",
    name: "Import Investors",
    route: "/dashboard/upload-file",
    icon: ImpFiles,
    hoverIcon: ImpFilesHover,
  },
];

const subNavigationData = [
  {
    id: "sipMining",
    name: "SIP Mining",
    route: "/dashboard/sip-mining",
    icon: SIPMining,
    hoverIcon: SIPMiningHover,
  },
  {
    id: "sipBusinessReport",
    name: "SIP Business Report",
    route: "/dashboard/sip-business-report",
    icon: SIPBusiness,
    hoverIcon: SIPBusinessHover,
  },
  {
    id: "fileImportHistory",
    name: "File Import History",
    route: "/dashboard/file-import-history",
    icon: FileImportHistory,
    hoverIcon: FileImportHistoryHover,
  },
  {
    id: "complianceReport",
    name: "Compliance Report",
    route: "/dashboard/compliance-report",
    icon: ComplianceReport,
    hoverIcon: ComplianceReportHover,
  },
  {
    id: "inactiveClients",
    name: "Inactive Investors",
    route: "/dashboard/inactive-clients",
    icon: InactiveClients,
    hoverIcon: InactiveClientsHover,
  },
  {
    id: "missingSip",
    name: "Missing Sip",
    route: "/dashboard/missing-sip",
    icon: MissingSIP,
    hoverIcon: MissingSIPHover,
  },
];

export { navigationData, subNavigationData };