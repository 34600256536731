import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { baseUrl } from "../../utils/baseUrl";
import TableSkeleton from "../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("proposal_no", {
    header: "Order Number",
    size: 40,
  }),
  columnHelper.accessor("clientName", {
    header: "Investor Name",
    size: 40,
    Cell: (cell) => cell.row.original.client_id.name,
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: " 7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.sip_status}
      </span>
    ),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => "₹ " + cell.row.original.amount,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const InactivSIP = () => {
  const Token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [executedSip, setExecutedSip] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    GetExecutedAPI();
  }, []);

  const GetExecutedAPI = async () => {
    try {
      const url = `${baseUrl}/partnerPortal/sip/listExpired`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const sips = response.data.data;
      sips.forEach((proposal, index) => {
        proposal.slno = index + 1;
      });
      setLoading(false);
      setExecutedSip(sips);
      setEmployees(sips);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const handleExportAllData = () => {
    const rowData = executedSip.map((data) => ({
      proposal_no: data.proposal_no,
      clientName: data.client_id.name,
      metal_type: data.metal_type,
      amount: data.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportSelectedRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_no: row.original.proposal_no,
      clientName: row.original.client_id.name,
      metal_type: row.original.metal_type,
      amount: row.original.amount,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportAllData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() =>
            handleExportSelectedRows(table.getSelectedRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <>{loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}</>
  );
};

export default InactivSIP;
