import React, { useState } from "react";
import { TextField, Grid } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment/moment";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, Tooltip } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Col } from "react-bootstrap";
import TableSkeleton from "../../utils/TableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("_id", {
    header: "Proposal Id",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?._id);
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?._id}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("client_id", {
    header: "Investor Id",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?.client_id);
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?.client_id}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("client_id.name", {
    header: "Investor Name",
    size: 40,
    Cell: (cell) => cell.row.original.client_id.name || "N/A",
  }),
  columnHelper.accessor("proposal_no", {
    header: "Proposal No",
    size: 40,
    Cell: (cell) => cell.row.original.proposal_no || "N/A",
  }),
  columnHelper.accessor("day_of_month", {
    header: "Day Of Month",
    size: 40,
    Cell: ({ cell }) => {
      const day = cell.row.original.day_of_month;
      if (!day) return "N/A";
      const getOrdinalSuffix = (num) => {
        if (num % 10 === 1 && num % 100 !== 11) return `${num}st`;
        if (num % 10 === 2 && num % 100 !== 12) return `${num}nd`;
        if (num % 10 === 3 && num % 100 !== 13) return `${num}rd`;
        return `${num}th`;
      };

      return `${getOrdinalSuffix(day)} Day Of Month`;
    },
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
    Cell: (cell) => cell.row.original.metal_type || "N/A",
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    Cell: (cell) => cell.row.original.amount || "N/A",
  }),
  columnHelper.accessor("quantity", {
    header: "Quantity",
    size: 40,
    Cell: (cell) => cell.row.original.quantity || "N/A",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: "7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.status || "N/A"}
      </span>
    ),
  }),
  columnHelper.accessor("sip_status", {
    header: "SIP Status",
    size: 40,
    Cell: (cell) => (
      <span
        style={{
          backgroundColor: "rgb(74 42 125)",
          padding: "7px 14px",
          borderRadius: "12px",
          fontSize: "13px",
          color: "white",
        }}
      >
        {cell.row.original.sip_status || "N/A"}
      </span>
    ),
  }),
  columnHelper.accessor("frequency", {
    header: "Frequency",
    size: 40,
    Cell: (cell) => cell.row.original.frequency || "N/A",
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: (cell) => moment(cell.row.original.createdAt).format("DD-MM-YYYY"),
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const MissingSip = () => {
  const Token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  useEffect(() => {
    fetchData();
  }, [check]);

  const fetchData = async () => {
    setLoading(true);
    console.log(name, "name>>>>>>>>>");
    const queryParams = new URLSearchParams({
      name: name,
      startDate: startDate,
      endDate: endDate,
    });
    try {
      const url = `${baseUrl}/report/sipMissing?${queryParams.toString()}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      if (response.data.error === false) {
        setLoading(false);
        setData(response.data.data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to API", error);
    }
  };

  // Handle Search
  const handleSearch = () => {
    fetchData();
  };

  const handleRefresh = () => {
    setName("");
    setStartDate("");
    setEndDate("");
    setCheck(!check);
  };

  // Utility function to get the ordinal suffix of a number
  const getOrdinalSuffix = (num) => {
    if (num % 10 === 1 && num % 100 !== 11) return `${num}st`;
    if (num % 10 === 2 && num % 100 !== 12) return `${num}nd`;
    if (num % 10 === 3 && num % 100 !== 13) return `${num}rd`;
    return `${num}th`;
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_id: row.original._id || "N/A",
      client_id: row.original.client_id?._id || "N/A",
      investor_name: row.original.client_id?.name || "N/A",
      proposal_no: row.original.proposal_no || "N/A",
      day_of_month: row.original.day_of_month
        ? `${getOrdinalSuffix(row.original.day_of_month)} Day Of Month`
        : "N/A",
      metal_type: row.original.metal_type || "N/A",
      amount: row.original.amount || "N/A",
      quantity: row.original.quantity || "N/A",
      status: row.original.status || "N/A",
      sip_status: row.original.sip_status || "N/A",
      frequency: row.original.frequency || "N/A",
      created_at: row.original.createdAt
        ? moment(row.original.createdAt).format("DD-MM-YYYY")
        : "N/A",
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = data.map((data) => ({
      proposal_id: data._id || "N/A",
      client_id: data.client_id?._id || "N/A",
      investor_name: data.client_id?.name || "N/A",
      proposal_no: data.proposal_no || "N/A",
      day_of_month: data.day_of_month
        ? `${getOrdinalSuffix(data.day_of_month)} Day Of Month`
        : "N/A",
      metal_type: data.metal_type || "N/A",
      amount: data.amount || "N/A",
      quantity: data.quantity || "N/A",
      status: data.status || "N/A",
      sip_status: data.sip_status || "N/A",
      frequency: data.frequency || "N/A",
      created_at: data.createdAt
        ? moment(data.createdAt).format("DD-MM-YYYY")
        : "N/A",
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Missing Sip
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <div className="mb-4">
        <div className="search-container p-4 border rounded">
          <Grid container spacing={3}>
            {/* Name Input */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Start Date and End Date Inputs */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Buttons in the Same Row */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    startIcon={<RefreshIcon />}
                    onClick={handleRefresh}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </div>
  );
};

export default MissingSip;
