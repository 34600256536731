import React from "react";
import Button from "@mui/material/Button";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment/moment";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { ToastContainer, toast } from "react-toastify";
import TableSkeleton from "../../../utils/TableSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("_id", {
    header: "Proposal Id",
    size: 30,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?._id);
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?._id}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("client_id.name", {
    header: "Investor Name",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?.client_id._id);
        toast.success("Copied to clipboard!");
      };

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={cell.row?.original?.client_id._id}>
            <IconButton
              onClick={handleCopy}
              size="small"
              sx={{ marginLeft: "8px" }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <span>{cell.row?.original?.client_id.name}</span>
        </div>
      );
    },
  }),

  columnHelper.accessor("proposal_no", {
    header: "Proposal No",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("metal_type", {
    header: "Metal Type",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("quantity", {
    header: "Quantity",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("Price", {
    header: "Price",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("sip_status", {
    header: "SIP Status",
    size: 40,
    cell: ({ value }) => (value ? value : "N/A"),
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: ({ cell }) => {
      const updatedAt = cell.row.original.updatedAt;
      return (
        <div style={{ textAlign: "center" }}>
          {moment(updatedAt).format("DD-MM-YYYY")}
        </div>
      );
    },
  }),
  columnHelper.accessor("updatedAt", {
    header: "Updated At",
    size: 40,
    Cell: ({ cell }) => {
      const updatedAt = cell.row.original.updatedAt;
      return (
        <div style={{ textAlign: "center" }}>
          {moment(updatedAt).format("DD-MM-YYYY")}
        </div>
      );
    },
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const LinkExpire = ({ data, loading }) => {
  
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      proposal_id: row.original._id || "N/A",
      investor_name: row.original.client_id?.name || "N/A",
      client_id: row.original.client_id?._id || "N/A",
      proposal_no: row.original.proposal_no || "N/A",
      metal_type: row.original.metal_type || "N/A",
      amount: row.original.amount || "N/A",
      quantity: row.original.quantity || "N/A",
      price: row.original.Price || "N/A",
      status: row.original.status || "N/A",
      sip_status: row.original.sip_status || "N/A",
      created_at: row.original.createdAt
        ? moment(row.original.createdAt).format("DD-MM-YYYY")
        : "N/A",
      updated_at: row.original.updatedAt
        ? moment(row.original.updatedAt).format("DD-MM-YYYY")
        : "N/A",
    }));

    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = data.map((data) => ({
      proposal_id: data._id || "N/A",
      investor_name: data.client_id?.name || "N/A",
      client_id: data.client_id?._id || "N/A",
      proposal_no: data.proposal_no || "N/A",
      metal_type: data.metal_type || "N/A",
      amount: data.amount || "N/A",
      quantity: data.quantity || "N/A",
      price: data.Price || "N/A",
      status: data.status || "N/A",
      sip_status: data.sip_status || "N/A",
      created_at: data.createdAt
        ? moment(data.createdAt).format("DD-MM-YYYY")
        : "N/A",
      updated_at: data.updatedAt
        ? moment(data.updatedAt).format("DD-MM-YYYY")
        : "N/A",
    }));

    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <ToastContainer />
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </>
  );
};

export default LinkExpire;
