import React, { useState, useEffect } from "react";
import { TextField, Button, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Col } from "react-bootstrap";
import Initialized from "./Initialized";
import Active from "./Active";
import Cancelled from "./Cancelled";
import LinkExpire from "./LinkExpire";
import BankApprovalPanding from "./BankApprovalPending";
import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";
import TableSkeleton from "../../../utils/TableSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";

const SipMining = () => {
  const token = sessionStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("initialized");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(false);

  const statusMap = {
    initialized: "Initialized",
    active: "ACTIVE",
    cancelled: "CANCELLED",
    linkExpire: "LINK_EXPIRED",
    bankApprovalPending: "BANK_APPROVAL_PENDING",
  };

  const fetchData = async (status) => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      name: name,
      startDate: startDate,
      endDate: endDate,
      status: status,
    });

    try {
      const url = `${baseUrl}/report/sip?${queryParams.toString()}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, {
        headers,
      });
      setData(response.data.data || []);
    } catch (err) {
      console.error("API Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(statusMap[activeTab]);
  }, [activeTab, check]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setName("");
    setStartDate("");
    setEndDate("");
  };
  // Handle Search
  const handleSearch = () => {
    fetchData(statusMap[activeTab]);
  };
  const handleRefresh = () => {
    setName("");
    setStartDate("");
    setEndDate("");
    setCheck(!check);
  };

  const renderTable = () => {
    if (loading) return <TableSkeleton />;

    switch (activeTab) {
      case "initialized":
        return <Initialized data={data} />;
      case "active":
        return <Active data={data} />;
      case "cancelled":
        return <Cancelled data={data} />;
      case "linkExpire":
        return <LinkExpire data={data} />;
      case "bankApprovalPending":
        return <BankApprovalPanding data={data} />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Sip Mining
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <div className="row mt-2 p-2 d-flex justify-content-center">
        <div
          className="col-sm-12 d-flex justify-content-center"
          style={{ overflowX: "auto", gap: "2rem" }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              sx: {
                height: "0px",
                overflow: "auto",
              },
            }}
          >
            <Tab
              value="initialized"
              label="Initialized"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "initialized" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="active"
              label="Active"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor: activeTab === "active" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="cancelled"
              label="Cancelled"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "cancelled" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="linkExpire"
              label="Link Expired"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "linkExpire" ? "#dcdaff" : "inherit",
              }}
            />
            <Tab
              value="bankApprovalPending"
              label="Bank Approval Pending"
              sx={{
                fontSize: "17px",
                color: "#4a2a7d",
                fontWeight: "600",
                margin: "0rem 1rem",
                padding: "0rem 3rem",
                borderRadius: "8px",
                textTransform: "capitalize",
                backgroundColor:
                  activeTab === "bankApprovalPending" ? "#dcdaff" : "inherit",
              }}
            />
          </Tabs>
        </div>
      </div>
      <div className="row mt-4">
        <div className="mb-4">
          <div className="search-container p-4 border rounded">
            <Grid container spacing={3}>
              {/* Name Input */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{ style: { height: "6px" } }}
                />
              </Grid>

              {/* Start Date and End Date Inputs */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  inputProps={{ style: { height: "6px" } }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  inputProps={{ style: { height: "6px" } }}
                />
              </Grid>

              {/* Buttons in the Same Row */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      startIcon={<RefreshIcon />}
                      onClick={handleRefresh}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="col-md-12">{renderTable()}</div>
      </div>
    </div>
  );
};

export default SipMining;
