import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import axios from "axios";
import "./main-blogs.css";
import Navbar1 from "../../../components/Navbar";
import Footer from "../../../landing_page/Footer";
import "../../../landing_page/offers.css";
import { baseUrl } from "../../../utils/baseUrl";

const Blogs = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch blog posts
    axios
      .get(`${baseUrl}/admin_panel/blog_PostList`)
      .then((response) => {
        setBlogPosts(response.data.data);
        setFilteredData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });

    axios
      .get(`${baseUrl}/admin_panel/category_List`)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSearch(e.target.value, filter);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    filterAndSearch(searchTerm, e.target.value);
  };

  const filterAndSearch = (term, filter) => {
    let filteredResults = blogPosts;

    if (filter) {
      filteredResults = filteredResults.filter(
        (item) => item.categoryId === filter
      );
    }

    if (term) {
      filteredResults = filteredResults.filter((item) =>
        item.title.toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredData(filteredResults);
  };

  const handleShare = () => {
    alert("Share this blog post!");
  };

  return (
    <>
      <Navbar1 />
      <div className="offerings-container">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
            padding: " 9px",
          }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12 col-md-6 our-blogs-title">
              <h2 className="offerings-title">Our Blogs</h2>
            </div>
            <div className="col-12 col-md-6 our-blogs-input">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px #4a2a7d solid",
                  borderRadius: "5px",
                  maxWidth: "100%",
                }}
              >
                <input
                  type="text"
                  placeholder="Search Blog"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    flex: "1",
                    width: "13rem",
                  }}
                />
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  style={{
                    padding: "12px",
                    borderRadius: "5px",
                    border: "none",
                    backgroundColor: "#4a2a7d",
                    color: "white",
                    paddingLeft: "34px",
                  }}
                >
                  <option value="" style={{ color: "white" }}>
                    All
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat._id}>
                      {cat.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "3rem",
          }}
        >
          <div className="blog-grid">
            {filteredData.map((post, index) => (
              <BlogCard
                key={index}
                blogId={post._id}
                author={"Batuk"}
                title={post.title}
                title_url={post.title_url}
                description={post.description}
                image={post.image}
                likes={post.likes}
                createdAt={post.createdAt}
                onShare={handleShare}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
