import React, { useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Ci from "../assets/images/Curated Icons.png";
import Cd from "../assets/images/Completely digitalised.png";
import Bs from "../assets/images/Bank Grade Security.png";
import Hc from "../assets/images/Help Center.png";
import image1 from "../assets/images/1.png";
import image2 from "../assets/images/2.png";
import image3 from "../assets/images/3.png";
import NavBar1 from "../components/Navbar";
import { baseUrl } from "../utils/baseUrl";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [authError, setAuthError] = useState("");

  const siteKey = process.env.REACT_APP_SITE_KEY; // Ensure this is set in your .env

  const isEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validate = () => {
    let emailError = "";

    if (!email) {
      emailError = "Email is required";
    } else if (!isEmail(email)) {
      emailError = "Invalid email format";
    }

    if (emailError) {
      setErrors({ email: emailError });
      return false;
    }

    return true;
  };

  const handleReCAPTCHAResponse = async (token) => {
    if (!token) {
      setAuthError("Please complete the reCAPTCHA verification.");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/forgotpassword`, {
        email: email.toLowerCase(),
        recaptchaToken: token, // Send the token to your server
      });
      if (response.data.error === false) {
        toast.success(response.data.message);
      } else {
        setAuthError(
          response.data.message || "An error occurred. Please try again."
        );
        toast.error(
          response.data.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      setAuthError("An error occurred. Please try again.");
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validate();
    if (!isValid) return;

    // Execute reCAPTCHA v3
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, { action: "submit" })
        .then((token) => {
          handleReCAPTCHAResponse(token);
        })
        .catch(() => {
          setAuthError("reCAPTCHA verification failed. Please try again.");
        });
    });
  };

  return (
    <>
      <NavBar1 />
      <ToastContainer />
      <div fluid className="otp-form-container" style={{ height: "92.9vh" }}>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div
            className="text-center bg-light-primary full-height"
            style={{ flex: "0 0 41.666667%", maxWidth: "41.666667%" }}
          >
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              pause={false}
              className="mt-xl-10 mt-lg-5 mt-md-3 mt-sm-1"
            >
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image1}
                  alt="First slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image2}
                  alt="Second slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image3}
                  alt="Third slide"
                  style={{ marginTop: "10rem" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div>
            <div
              className="otp-form d-flex flex-column justify-content-center"
              style={{ height: "80vh" }}
            >
              <div
                className="form-center"
                style={{ marginTop: "11rem", marginBottom: "3rem" }}
              >
                <h2>Forgot Password</h2>
                <p>If you forgot your password please enter your email here</p>
                <div
                  className="p-4 mx-auto"
                  style={{ width: "100%", maxWidth: "24rem" }}
                >
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        style={{
                          border: "2px solid #4a2a7d",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                    {authError && (
                      <div className="text-danger">{authError}</div>
                    )}
                    <button
                      className="btn py-2 w-100"
                      style={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        marginTop: "20px",
                        borderRadius: "30px",
                      }}
                      type="submit"
                    >
                      Forgot Password
                    </button>
                    <p style={{ marginTop: "1rem", fontSize: "15px" }}>
                      <Link to="/login" style={{ color: "#4a2a7d" }}>
                        Login Now
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
              <div className="row features">
                <div
                  className="d-flex justify-content-center mb-3 mb-md-0"
                  style={{ flex: "0 0 25%", maxWidth: "25%" }}
                >
                  <img
                    src={Ci}
                    className="bottom-logo img-fluid"
                    alt="Curated Investing"
                    loading="lazy"
                  />
                </div>
                <div
                  className="d-flex justify-content-center mb-3 mb-md-0"
                  style={{ flex: "0 0 25%", maxWidth: "25%" }}
                >
                  <img
                    src={Cd}
                    className="bottom-logo img-fluid"
                    alt="Completely Digitalised"
                    loading="lazy"
                  />
                </div>
                <div
                  className="d-flex justify-content-center mb-3 mb-md-0"
                  style={{ flex: "0 0 25%", maxWidth: "25%" }}
                >
                  <img
                    src={Bs}
                    className="bottom-logo img-fluid"
                    alt="Bank Grade Security"
                    loading="lazy"
                  />
                </div>
                <div
                  className="d-flex justify-content-center mb-3 mb-md-0"
                  style={{ flex: "0 0 25%", maxWidth: "25%" }}
                >
                  <img
                    src={Hc}
                    className="bottom-logo img-fluid"
                    alt="Help Center"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
