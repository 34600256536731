import React, { useState } from "react";
import { TextField, Grid } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment/moment";
import { useSpring, animated } from "@react-spring/web";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, Tooltip } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Col } from "react-bootstrap";
import TableSkeleton from "../../utils/TableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";
import "../../assets/css/SipBusinessReport.css";
import ProfileLogo from "../../assets/Icons/ProfileIcon.png";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("_id", {
    header: "Buy Id",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?._id);
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?._id}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("result.data.userName", {
    header: "Investor Name",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.userName || "N/A",
  }),
  columnHelper.accessor("payment_id", {
    header: "Payment ID",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?.payment_id);
        toast.success("Copied to clipboard!");
      };

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={cell.row?.original?.payment_id}>
            <IconButton
              onClick={handleCopy}
              size="small"
              sx={{ marginLeft: "8px" }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <span>{cell.row?.original?.payment_id}</span>
        </div>
      );
    },
  }),
  columnHelper.accessor("order_id", {
    header: "Order ID",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?.order_id);
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?.order_id}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("message", {
    header: "Message",
    size: 60,
    Cell: (cell) => cell.row.original.message || "N/A",
  }),
  columnHelper.accessor("result.data.quantity", {
    header: "Quantity",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.quantity || "N/A",
  }),
  columnHelper.accessor("result.data.totalAmount", {
    header: "Total Amount",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.totalAmount || "N/A",
  }),
  columnHelper.accessor("result.data.metalType", {
    header: "Metal Type",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.metalType || "N/A",
  }),
  columnHelper.accessor("result.data.rate", {
    header: "Rate",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.rate || "N/A",
  }),
  columnHelper.accessor("result.data.transactionId", {
    header: "Transaction ID",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(
          cell.row?.original?.result.data.transactionId
        );
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?.result.data.transactionId}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("result.data.invoiceNumber", {
    header: "Invoice Number",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(
          cell.row?.original?.result.data.invoiceNumber
        );
        toast.success("Copied to clipboard!");
      };

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title={cell.row?.original?.result.data.invoiceNumber}>
            <IconButton
              onClick={handleCopy}
              size="small"
              sx={{ marginLeft: "8px" }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <span>{cell.row?.original?.result.data.invoiceNumber}</span>
        </div>
      );
    },
  }),
  columnHelper.accessor("result.data.goldBalance", {
    header: "Gold Balance",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.goldBalance || "N/A",
  }),
  columnHelper.accessor("result.data.silverBalance", {
    header: "Silver Balance",
    size: 40,
    Cell: (cell) => cell.row.original.result?.data?.silverBalance || "N/A",
  }),
  columnHelper.accessor("result.data.merchantTransactionId", {
    header: "MerchantTransactionId",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(
          cell.row?.original?.result.data.merchantTransactionId
        );
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?.result.data.merchantTransactionId}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("result.data.uniqueId", {
    header: "Unique Id",
    size: 40,
    Cell: (cell) => cell.row.original.result.data.uniqueId || "N/A",
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: ({ cell }) => {
      const createdAt = cell.row.original.createdAt;
      return (
        <div style={{ textAlign: "center" }}>
          {moment(createdAt).format("DD-MM-YYYY")}
        </div>
      );
    },
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const SipBusinessReport = () => {
  const Token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    fetchData();
  }, [check]);

  const { number } = useSpring({
    from: { number: 0 },
    to: { number: amount },
    config: { duration: 1000 },
  });

  const fetchData = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      name: name,
      startDate: startDate,
      endDate: endDate,
    });
    try {
      const url = `${baseUrl}/report/sipBusinessReport?${queryParams.toString()}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      if (response.data.error === false) {
        setLoading(false);
        setAmount(response.data.total_amount);
        setData(response.data.data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to API", error);
    }
  };

  // Handle Search
  const handleSearch = () => {
    fetchData();
  };

  const handleRefresh = () => {
    setName("");
    setStartDate("");
    setEndDate("");
    setCheck(!check);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      investor_id: row.original._id,
      user_name: row.original.result?.data?.userName || "N/A",
      payment_id: row.original.payment_id || "N/A",
      order_id: row.original.order_id || "N/A",
      message: row.original.message || "N/A",
      quantity: row.original.result?.data?.quantity || "N/A",
      total_amount: row.original.result?.data?.totalAmount || "N/A",
      metal_type: row.original.result?.data?.metalType || "N/A",
      rate: row.original.result?.data?.rate || "N/A",
      transaction_id: row.original.result?.data?.transactionId || "N/A",
      invoice_number: row.original.result?.data?.invoiceNumber || "N/A",
      gold_balance: row.original.result?.data?.goldBalance || "N/A",
      silver_balance: row.original.result?.data?.silverBalance || "N/A",
      mobile_number: row.original.result?.data?.mobileNumber || "N/A",
      merchant_transaction_id:
        row.original.result?.data?.merchantTransactionId || "N/A",
      unique_id: row.original.result?.data?.uniqueId || "N/A",
      created_at: moment(row.original.createdAt).format("DD-MM-YYYY"),
    }));

    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = data.map((data) => ({
      investor_id: data._id,
      user_name: data.result?.data?.userName || "N/A",
      payment_id: data.payment_id || "N/A",
      order_id: data.order_id || "N/A",
      message: data.message || "N/A",
      quantity: data.result?.data?.quantity || "N/A",
      total_amount: data.result?.data?.totalAmount || "N/A",
      metal_type: data.result?.data?.metalType || "N/A",
      rate: data.result?.data?.rate || "N/A",
      transaction_id: data.result?.data?.transactionId || "N/A",
      invoice_number: data.result?.data?.invoiceNumber || "N/A",
      gold_balance: data.result?.data?.goldBalance || "N/A",
      silver_balance: data.result?.data?.silverBalance || "N/A",
      mobile_number: data.result?.data?.mobileNumber || "N/A",
      merchant_transaction_id:
        data.result?.data?.merchantTransactionId || "N/A",
      unique_id: data.result?.data?.uniqueId || "N/A",
      created_at: moment(data.createdAt).format("DD-MM-YYYY"),
    }));

    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Sip Business Report
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <div className="sip-business-container">
        <div className="sip-business-card">
          <div className="icon">
            <img src={ProfileLogo} alt="Profile Logo" />
          </div>
          <div className="content">
            <h2>SIP Total Amount</h2>
            <p>Batuk Partner</p>
          </div>
          <div className="actions">
            <p className="download-button">₹</p>
            <animated.span className="download-button">
              {number.to((n) => Math.floor(n))}
            </animated.span>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="search-container p-4 border rounded">
          <Grid container spacing={3}>
            {/* Name Input */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Start Date and End Date Inputs */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Buttons in the Same Row */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    startIcon={<RefreshIcon />}
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </div>
  );
};

export default SipBusinessReport;
