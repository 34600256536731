import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box } from "@mui/material";
import { baseUrl } from "../../utils/baseUrl";
import { Col } from "react-bootstrap";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("name", {
    header: "Investor Name",
    size: 40,
  }),
  columnHelper.accessor("mobileNo", {
    header: "Mobile Number",
    size: 40,
  }),
];

const CreateNewOrder = () => {
  const [clientList, setClientList] = useState([]);
  const Token = sessionStorage.getItem("token");
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientList();
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableRowSelection: false,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ cell }) => (
      <Box>
        <Button
          type="button"
          variant="contained"
          onClick={() => addOrder(cell.row.original._id)}
          style={{ textTransform: "none" }}
        >
          Place Order
        </Button>
      </Box>
    ),
  });

  const fetchClientList = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const clients = response.data.data;
      setClientList(clients);
      setEmployees(clients);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const addOrder = (clientId) => {
    navigate(`/dashboard/product/${clientId}`);
  };

  return (
    <>
      <div className="row">
        <div
          className="col-md-12 ms-2 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "15%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              Select Investor
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <MaterialReactTable table={table} />
    </>
  );
};

export default CreateNewOrder;
