import React, { useState } from "react";
import {
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Col } from "react-bootstrap";
import TableSkeleton from "../../utils/TableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TablePagination,
} from "@mui/material";
import "../../assets/css/InvestorView.css";
import Logo from "../../assets/images/pdf header.png";
import FooterImage from "../../assets/images/footer (2).png";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { FaDownload } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { BsFillSendFill } from "react-icons/bs";

const InvestorView = () => {
  const Token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [mailLoading, setMailLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(false);
  const [goldPage, setGoldPage] = useState(0);
  const [silverPage, setSilverPage] = useState(0);
  const [goldRowsPerPage, setGoldRowsPerPage] = useState(10);
  const [silverRowsPerPage, setSilverRowsPerPage] = useState(10);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const navigate = useNavigate();

  const goldData = data?.goldData?.length;
  const silverData = data?.silverData?.length;

  const handleGoldChangePage = (event, newPage) => {
    setGoldPage(newPage);
  };

  const handleGoldChangeRowsPerPage = (event) => {
    setGoldRowsPerPage(parseInt(event.target.value, 10));
    setGoldPage(0);
  };

  const goldPaginatedData = data?.goldData?.slice(
    goldPage * goldRowsPerPage,
    goldPage * goldRowsPerPage + goldRowsPerPage
  );

  const handleSilverChangePage = (event, newPage) => {
    setSilverPage(newPage);
  };

  const handleSilverChangeRowsPerPage = (event) => {
    setSilverRowsPerPage(parseInt(event.target.value, 10));
    setSilverPage(0);
  };

  const silverPaginatedData = data?.silverData?.slice(
    silverPage * silverRowsPerPage,
    silverPage * silverRowsPerPage + silverRowsPerPage
  );

  const { id } = useParams();

  useEffect(() => {
    const today = new Date();
    const defaultEndDate = moment(today).format("DD MMM YYYY");
    const defaultStartDate = moment(today.setDate(today.getDate() - 30)).format(
      "DD MMM YYYY"
    );

    setSelectedStartDate(defaultStartDate);
    setSelectedEndDate(defaultEndDate);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    fetchData(defaultStartDate, defaultEndDate);
    // eslint-disable-next-line
  }, [check]);

  const fetchData = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      startDate: startDate,
      endDate: endDate,
    });

    try {
      const url = `${baseUrl}/partnerPortal/userReports/${id}?${queryParams.toString()}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      if (response.data.error === false) {
        setLoading(false);
        setData(response?.data?.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to API", error);
    }
  };

  const sendPdf = async (pdfBlob, action) => {
    if (action === "download") setPdfLoading(true);
    if (action === "view") setViewLoading(true);
    if (action === "mail") setMailLoading(true);
    const selectedStartDate =
      startDate || moment().subtract(30, "days").format("DD MMM YYYY");
    const selectedEndDate = endDate || moment().format("DD MMM YYYY");

    try {
      const formData = new FormData();
      formData.append("file", pdfBlob, "Transaction_Report.pdf");
      formData.append("startDate", selectedStartDate);
      formData.append("endDate", selectedEndDate);

      const url = `${baseUrl}/partnerPortal/userReports/sendMail/${id}`;
      const headers = {
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.post(url, formData, { headers });
      if (response.data.error === false) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    } finally {
      setPdfLoading(false);
      setViewLoading(false);
      setMailLoading(false);
    }
  };

  const handleSearch = () => {
    const selectedEndDate = endDate || moment().format("DD-MM-YYYY");
    fetchData(startDate, selectedEndDate);

    setSelectedStartDate(startDate);
    setSelectedEndDate(selectedEndDate);
  };

  const handleRefresh = () => {
    setStartDate("");
    setEndDate("");
    setSelectedStartDate("");
    setSelectedEndDate("");
    setCheck(!check);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#4a2a7d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const generatePdf = () => {
    if (!data) return;

    const doc = new jsPDF();

    doc.addImage(Logo, "PNG", 0, 0, 210, 25);

    const footerImageHeight = 15;
    const footer = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;

        doc.addImage(Logo, "PNG", 0, 0, pageWidth - 0, 25);

        doc.addImage(
          FooterImage,
          "PNG",
          0,
          pageHeight - footerImageHeight - 0,
          pageWidth - 0,
          footerImageHeight
        );

        // Add footer text
        doc.setTextColor(238, 216, 129);
        doc.setFont("helvetica", "normal");
        doc.setFontSize(10);
        doc.text(`Page ${i} of ${pageCount}`, pageWidth - 30, pageHeight - 5, {
          align: "justify",
        });
      }
    };

    // Add content and tables to the document
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 58, 72);

    const userDetails = [
      ["Name", data?.userDetails?.name || "N/A"],
      ["Phone", data?.userDetails?.phone || "N/A"],
      ["DOB", data?.userDetails?.dob || "N/A"],
      ["Email", data?.userDetails?.email || "N/A"],
      [
        "Address",
        data?.address
          ? `${data.address.line1 || "N/A"}, ${data.address.line2 || "N/A"}, ${
              data.address.city || "N/A"
            }, ${data.address.state || "N/A"}, ${data.address.zip || "N/A"}`
          : "N/A",
      ],
      ["Unique ID", data?.userDetails?.uniqueId || "N/A"],
    ];

    doc.autoTable({
      head: [["User Details", ""]],
      body: userDetails,
      startY: 30,
      theme: "plain",
      headStyles: {
        textColor: [0, 58, 72],
        fontSize: 16,
        fontStyle: "bold",
      },
      margin: {
        top: 30, // Top margin
        left: 10, // Left margin
        bottom: 20, // Bottom margin
        right: 10, // Right margin
      },
      didDrawPage: () => {
        doc.addImage(Logo, "PNG", 0, 0, 210, 25);
      },
    });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.setTextColor(100);
    doc.text(
      `Statement From ${startDate} To ${endDate}`,
      10,
      doc.lastAutoTable.finalY + 10
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 58, 72);
    doc.text("Gold Transactions", 10, doc.lastAutoTable.finalY + 20);
    const goldData = data?.goldData || [];
    const goldTransactionRows = goldData.map((row) => [
      moment(row.createdAt).format("DD MMM YYYY"),
      row.description,
      row.type === "buy" ? row.gram : "",
      row.type === "sell" ? row.gram : "",
      row.amount,
      row.balance,
    ]);

    doc.setFont("helvetica", "bold");
    goldTransactionRows.push([
      "Total",
      "",
      data?.goldSummary?.totalBuyGram || "N/A",
      data?.goldSummary?.totalSellGram || "N/A",
      (data?.goldSummary?.totalBuyAmount || 0) -
        (data?.goldSummary?.totalSellAmount || 0),
      data?.goldData?.[data.goldData.length - 1]?.balance || "N/A",
    ]);

    doc.autoTable({
      head: [
        [
          "Txn Date",
          "Description",
          "Buy(g)",
          "Sell(g)",
          "Amount(Rs)",
          "Balance(g)",
        ],
      ],
      body: goldTransactionRows,
      startY: doc.lastAutoTable.finalY + 25,
      theme: "grid",
      headStyles: {
        fillColor: [0, 58, 72],
        textColor: [255, 255, 255],
        fontSize: 12,
        fontStyle: "bold",
      },
      bodyStyles: {
        padding: 4,
      },
      columnStyles: {
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
      },
      margin: {
        top: 30, // Top margin
        left: 10, // Left margin
        bottom: 20, // Bottom margin
        right: 10, // Right margin
      },
      didParseCell: (data) => {
        if (data.row.index === goldTransactionRows.length - 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 10; // Apply bold style
        }
      },
      didDrawPage: () => {
        doc.addImage(Logo, "PNG", 0, 0, 210, 25);
      },
    });

    // Gold Summary Section
    const goldSummary = [
      ["Total Gold Bought (gm)", data?.goldSummary?.totalBuyGram || "N/A"],
      ["Total Gold Sold (gm)", data?.goldSummary?.totalSellGram || "N/A"],
      [
        "Total Gold Buy Amount (Rs)",
        data?.goldSummary?.totalBuyAmount || "N/A",
      ],
      [
        "Total Gold Sell Amount (Rs)",
        data?.goldSummary?.totalSellAmount || "N/A",
      ],
    ];

    doc.autoTable({
      head: [["Gold Summary", ""]],
      body: goldSummary,
      startY: doc.lastAutoTable.finalY + 5,
      theme: "plain",
      headStyles: {
        textColor: [0, 58, 72],
        fontSize: 16,
        fontStyle: "bold",
      },
      margin: {
        top: 30, // Top margin
        left: 10, // Left margin
        bottom: 20, // Bottom margin
        right: 10, // Right margin
      },
      didDrawPage: () => {
        doc.addImage(Logo, "PNG", 0, 0, 210, 25);
      },
    });

    // Silver Transactions Section
    const silverData = data?.silverData || [];
    const silverTransactionRows = silverData.map((row) => [
      moment(row.createdAt).format("DD MMM YYYY"),
      row.description,
      row.type === "buy" ? row.gram : "",
      row.type === "sell" ? row.gram : "",
      row.amount,
      row.balance,
    ]);

    silverTransactionRows.push([
      "Total",
      "",
      data?.silverSummary?.totalBuyGram || "N/A",
      data?.silverSummary?.totalSellGram || "N/A",
      (data?.silverSummary?.totalBuyAmount || 0) -
        (data?.silverSummary?.totalSellAmount || 0),
      data?.silverData?.[data.silverData.length - 1]?.balance || "N/A",
    ]);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 58, 72);
    doc.text("Silver Transactions", 10, doc.lastAutoTable.finalY + 10);

    doc.autoTable({
      head: [
        [
          "Txn Date",
          "Description",
          "Buy(g)",
          "Sell(g)",
          "Amount(Rs)",
          "Balance(g)",
        ],
      ],
      body: silverTransactionRows,
      startY: doc.lastAutoTable.finalY + 15,
      theme: "grid",
      headStyles: {
        fillColor: [0, 58, 72],
        textColor: [255, 255, 255],
        fontSize: 12,
        fontStyle: "bold",
      },
      columnStyles: {
        2: { halign: "right" }, // "Buy(g)"
        3: { halign: "right" }, // "Sell(g)"
        4: { halign: "right" }, // "Amount"
        5: { halign: "right" }, // "Balance(g)"
      },
      margin: {
        top: 30, // Top margin
        left: 10, // Left margin
        bottom: 20, // Bottom margin
        right: 10, // Right margin
      },
      showHead: "everyPage",

      didParseCell: (data) => {
        if (data.row.index === silverTransactionRows.length - 1) {
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 10; // Apply bold style
        }
      },

      didDrawPage: () => {
        doc.addImage(Logo, "PNG", 0, 0, 210, 25);
      },
    });

    // Silver Summary Section
    const silverSummary = [
      [
        "Total Silver Bought (gm)",
        `${data?.silverSummary?.totalBuyGram || "N/A"}`,
      ],
      [
        "Total Silver Sold (gm)",
        `${data?.silverSummary?.totalSellGram || "N/A"}`,
      ],
      [
        "Total Silver Buy Amount (Rs)",
        `${data?.silverSummary?.totalBuyAmount || "N/A"}`,
      ],
      [
        "Total Silver Sell Amount (Rs)",
        `${data?.silverSummary?.totalSellAmount || "N/A"}`,
      ],
    ];

    doc.autoTable({
      head: [["Silver Summary", ""]],
      body: silverSummary,
      startY: doc.lastAutoTable.finalY + 10,
      theme: "plain",
      headStyles: {
        textColor: [0, 58, 72],
        fontSize: 16,
        fontStyle: "bold",
      },
      margin: {
        top: 30, // Top margin
        left: 10, // Left margin
        bottom: 20, // Bottom margin
        right: 10, // Right margin
      },
      didDrawPage: () => {
        doc.addImage(Logo, "PNG", 0, 0, 210, 25);
        footer(doc);
      },
    });

    doc.setFont("helvetica", "italic");
    doc.setFontSize(12);
    doc.setTextColor(100);
    doc.text(
      "**This is a computer generated statement and does not require a signature.",
      10,
      doc.lastAutoTable.finalY + 10
    );

    footer(doc);

    const pdfOutput = doc.output("bloburl");
    return pdfOutput;
  };

  const handleDownload = async () => {
    setPdfLoading(true); // Start loading
    try {
      const pdfOutput = generatePdf();
      const link = document.createElement("a");
      link.href = pdfOutput;
      link.download = "Transaction_Report.pdf";
      link.click();
    } finally {
      setPdfLoading(false); // Stop loading
    }
  };

  const handleView = async () => {
    setViewLoading(true); // Start loading
    try {
      const pdfOutput = generatePdf();
      window.open(pdfOutput, "_blank");
    } finally {
      setViewLoading(false); // Stop loading
    }
  };

  const handleSendPdf = async (startDate, endDate) => {
    setMailLoading(true); // Start loading
    try {
      const pdfOutput = generatePdf();
      const blob = await (await fetch(pdfOutput)).blob();
      await sendPdf(blob, startDate, endDate);
    } finally {
      setMailLoading(false); // Stop loading
    }
  };

  const handleBack = () => {
    navigate("/dashboard/client_table"); // Redirects to the home page
  };

  return (
    <div className="container-fluid" style={{ marginBottom: "3rem" }}>
      <ToastContainer />
      <div className="row">
        <div className="col-md-12 mt-2" style={{ display: "flex" }}>
          <IconButton onClick={handleBack}>
            <KeyboardBackspaceIcon />
          </IconButton>
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px 0px 0px 12px",
              }}
            >
              View Transactions
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <div className="mb-4">
        <div className="search-container mb-3" style={{ marginTop: "1rem" }}>
          <Grid
            container
            spacing={3}
            style={{ display: "flex", justifyContent: "end" }}
          >
            {/* Start Date and End Date Inputs */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(startDate, "DD MMM YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  setStartDate(moment(e.target.value).format("DD MMM YYYY"))
                }
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(endDate, "DD MMM YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  setEndDate(moment(e.target.value).format("DD MMM YYYY"))
                }
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Buttons in the Same Row */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    startIcon={<RefreshIcon />}
                    onClick={handleRefresh}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="investor-view-container">
          <div className="investor-view-card">
            <div className="investor-view-content">
              <div className="investor-view-column">
                <div className="align-table-data">
                  <dt className="col-6">
                    <h3>Name</h3>
                  </dt>
                  <dd className="col-6">
                    <p style={{ margin: "0" }}>
                      {data?.userDetails?.name || "N/A"}
                    </p>
                  </dd>
                </div>
                <div className="align-table-data">
                  <dt className="col-6">
                    <h3>Dob</h3>
                  </dt>
                  <dd className="col-6">
                    <p style={{ margin: "0" }}>
                      {data?.userDetails?.dob || "N/A"}
                    </p>
                  </dd>
                </div>
                <div className="align-table-data">
                  <dt className="col-6">
                    <h3>Phone</h3>
                  </dt>
                  <dd className="col-6">
                    <p style={{ margin: "0" }}>
                      {data?.userDetails?.phone || "N/A"}
                    </p>
                  </dd>
                </div>
                <div className="align-table-data">
                  <dt className="col-6">
                    <h3>Email</h3>
                  </dt>
                  <dd className="col-6">
                    <p style={{ margin: "0" }}>
                      {data?.userDetails?.email || "N/A"}
                    </p>
                  </dd>
                </div>
              </div>
              <div className="investor-view-column">
                <div className="align-table-data">
                  <dt className="col-6">
                    <h3>Address</h3>
                  </dt>
                  <dd className="col-6">
                    <p style={{ margin: "0" }}>
                      {data?.address
                        ? `${data.address.line1 || "N/A"}, ${
                            data.address.line2 || "N/A"
                          }, ${data.address.city || "N/A"}, ${
                            data.address.state || "N/A"
                          }, ${data.address.zip || "N/A"}`
                        : "N/A"}
                    </p>
                  </dd>
                </div>
                <div className="align-table-data">
                  <dt className="col-6">
                    <h3>Unique Id</h3>
                  </dt>
                  <dd className="col-6">
                    <p style={{ margin: "0" }}>
                      {data?.userDetails?.uniqueId || "N/A"}
                    </p>
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        {selectedStartDate && selectedEndDate ? (
          <h3>
            Statement From {selectedStartDate} To {selectedEndDate}
          </h3>
        ) : (
          <h3>
            Statement From {selectedStartDate} To {selectedEndDate}
          </h3>
        )}
      </div>
      <hr />
      {loading ? (
        <TableSkeleton />
      ) : data?.goldData?.length === 0 && data?.silverData?.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "gray",
          }}
        >
          No Transaction available
        </div>
      ) : (
        <>
          {/* Gold Data Table */}
          {data?.goldData?.length > 0 && (
            <>
              <h3 style={{ marginBottom: "0.5rem" }}>Gold Transactions</h3>
              <Paper>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 700 }}
                    aria-label="gold transactions table"
                  >
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Txn Date</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Buy(g)</StyledTableCell>
                        <StyledTableCell>Sell(g)</StyledTableCell>
                        <StyledTableCell>Amount(₹)</StyledTableCell>
                        <StyledTableCell>Balance(g)</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {goldPaginatedData?.map((row, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>
                            {moment(row.createdAt).format("DD MMM YYYY")}
                          </StyledTableCell>

                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell>
                            {row.type === "buy" ? row.gram : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.type === "sell" ? row.gram : ""}
                          </StyledTableCell>
                          <StyledTableCell>{row.amount}</StyledTableCell>
                          <StyledTableCell>{row.balance}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={data?.goldData?.length || 0}
                  rowsPerPage={goldRowsPerPage}
                  page={goldPage}
                  onPageChange={handleGoldChangePage}
                  onRowsPerPageChange={handleGoldChangeRowsPerPage}
                  style={{
                    zIndex: "3",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "baseline",
                  }}
                />
              </Paper>
            </>
          )}
          {goldData > 0 && (
            <div style={{ marginBottom: "1rem" }}>
              <h3>Gold Summary</h3>
              <div className="investor-view-content">
                <div className="investor-view-column">
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Gold Bought (gm)</p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        {data?.goldSummary?.totalBuyGram}
                      </p>
                    </dd>
                  </div>
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Gold Sold (gm)</p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        {data?.goldSummary?.totalSellGram}
                      </p>
                    </dd>
                  </div>
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Gold Buy Amount (₹) </p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        {data?.goldSummary?.totalBuyAmount}
                      </p>
                    </dd>
                  </div>
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Gold Sell Amount (₹)</p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        <p style={{ margin: "0" }}>
                          {data?.goldSummary?.totalSellAmount}
                        </p>
                      </p>
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr />

          {/* Silver Data Table */}
          {data?.silverData?.length > 0 && (
            <>
              <h3 style={{ marginBottom: "0.5rem" }}>Silver Transactions</h3>
              <Paper>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 700 }}
                    aria-label="silver transactions table"
                  >
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Txn Date</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Buy(g)</StyledTableCell>
                        <StyledTableCell>Sell(g)</StyledTableCell>
                        <StyledTableCell>Amount(₹)</StyledTableCell>
                        <StyledTableCell>Balance(g)</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {silverPaginatedData?.map((row, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>
                            {moment(row.createdAt).format("DD MMM YYYY")}
                          </StyledTableCell>

                          <StyledTableCell>{row.description}</StyledTableCell>
                          <StyledTableCell>
                            {row.type === "buy" ? row.gram : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.type === "sell" ? row.gram : ""}
                          </StyledTableCell>
                          <StyledTableCell>{row.amount}</StyledTableCell>
                          <StyledTableCell>{row.balance}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={data?.silverData?.length || 0}
                  rowsPerPage={silverRowsPerPage}
                  page={silverPage}
                  onPageChange={handleSilverChangePage}
                  onRowsPerPageChange={handleSilverChangeRowsPerPage}
                  style={{ zIndex: "3" }}
                />
              </Paper>
            </>
          )}
          {silverData > 0 && (
            <div style={{ marginBottom: "1rem" }}>
              <h3>Silver Summary</h3>
              <div className="investor-view-content">
                <div className="investor-view-column">
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Silver Bought (gm)</p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        {data?.silverSummary?.totalBuyGram}
                      </p>
                    </dd>
                  </div>
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Silver Sold (gm)</p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        {data?.silverSummary?.totalSellGram}
                      </p>
                    </dd>
                  </div>
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Silver Buy Amount (₹) </p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        {data?.silverSummary?.totalBuyAmount}
                      </p>
                    </dd>
                  </div>
                  <div className="align-table-data">
                    <dt className="col-6">
                      <p>Total Silver Sell Amount (₹)</p>
                    </dt>
                    <dd className="col-6">
                      <p style={{ margin: "0" }}>
                        <p style={{ margin: "0" }}>
                          {data?.silverSummary?.totalSellAmount}
                        </p>
                      </p>
                    </dd>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div
        className="mb-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Button
          style={{ marginRight: "1rem", backgroundColor: "#4a2a7d" }}
          disabled={pdfLoading}
          onClick={() => {
            if (goldData > 0 || silverData > 0) {
              handleDownload();
            } else {
              toast.warn("No transactions available");
            }
          }}
        >
          {pdfLoading && (
            <CircularProgress sx={{ color: "white" }} size="22px" />
          )}

          <Typography
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <FaDownload sx={{ color: "white", marginRight: "8px" }} />
            Download PDF
          </Typography>
        </Button>

        <Button
          style={{ marginRight: "1rem", backgroundColor: "#4a2a7d" }}
          disabled={viewLoading}
          onClick={() => {
            if (goldData > 0 || silverData > 0) {
              handleView();
            } else {
              toast.warn("No transactions available");
            }
          }}
        >
          {viewLoading && (
            <CircularProgress sx={{ color: "white !important" }} size="22px" />
          )}

          <Typography
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <FaEye sx={{ color: "white !important", marginRight: "8px" }} />
            View PDF
          </Typography>
        </Button>

        <Button
          style={{ backgroundColor: "#4a2a7d" }}
          disabled={mailLoading}
          onClick={() => {
            if (goldData > 0 || silverData > 0) {
              handleSendPdf();
            } else {
              toast.warn("No transactions available");
            }
          }}
        >
          {mailLoading && (
            <CircularProgress sx={{ color: "white" }} size="22px" />
          )}

          <Typography
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <BsFillSendFill
              sx={{
                color: "white !important",
                marginRight: "8px",
              }}
            />
            Send PDF to mail
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default InvestorView;
