import React, { useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/signup.css";
import FormPart1 from "../pages/EntityPartner/FormPart1";
import FormPart2 from "../pages/EntityPartner/FormPart2";
import FormPart3 from "../pages/EntityPartner/FormPart3";
import registerPic from "../assets/images/Individual registration.png";
import kycPic from "../assets/images/Kyc.png";
import bankPic from "../assets/images/Bank Details.png";
import { useNavigate } from "react-router-dom";
import NavBar1 from "../components/Navbar";
import { baseUrl } from "../utils/baseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IndiRegister = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    registerType: "entity",
    EntityType: "",
    Entityname: "",
    EntityidentificationNo: "",
    Entityemail: "",
    password: "",
    confirmPassword: "",
    panNo: "",
    Gstin: "",
    authpersonName: "",
    mobileNo: "",
    companyAddress: "",
    city: "",
    state: "",
    pincode: "",
    accHoldername: "",
    accNo: "",
    confirmaccNo: "",
    Ifsccode: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/partner_registeration`,
        formData
      );
      console.log("Form submitted", response.data.message);
      if (response && response.status === 200) {
        if (response.data.error === false) {
          const userData = response.data.data.userName;
          Swal.fire({
            text: "Your login details will be sent on your registered Email",
            title: `Your Partner code is "${userData}"`,
            icon: "success",
          });
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.err || error.response.data.message,
        });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        toast.error("Error submitting form. Please try again later.");
      }
    }
  };

  return (
    <>
      <NavBar1 />
      <div fluid className="otp-form-container" style={{ height: "92.9vh" }}>
        <ToastContainer />
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div
            className="text-center bg-light-primary full-height"
            style={{ flex: "0 0 41.666667%", maxWidth: "41.666667%" }}
          >
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              pause={false}
              className="mt-xl-10 mt-lg-5 mt-md-3"
            >
              <Carousel.Item>
                {step === 1 && (
                  <img
                    className="d-block w-100 img-height"
                    src={registerPic}
                    alt="First slide"
                    loading="lazy"
                    style={{ marginTop: "5rem" }}
                  />
                )}
                {step === 2 && (
                  <img
                    className="d-block w-100 img-height"
                    src={kycPic}
                    alt="First slide"
                    loading="lazy"
                    style={{ marginTop: "5rem" }}
                  />
                )}
                {step === 3 && (
                  <img
                    className="d-block w-100 img-height"
                    src={bankPic}
                    alt="First slide"
                    loading="lazy"
                    style={{ marginTop: "5rem" }}
                  />
                )}
              </Carousel.Item>
            </Carousel>
          </div>
          <Col xl={7} lg={7} md={12} sm={12}>
            <div className="">
              {step === 1 && (
                <FormPart1
                  formData={formData}
                  handleChange={handleChange}
                  nextStep={nextStep}
                />
              )}
              {step === 2 && (
                <FormPart2
                  formData={formData}
                  handleChange={handleChange}
                  nextStep={nextStep}
                  prevStep={prevStep}
                />
              )}
              {step === 3 && (
                <FormPart3
                  formData={formData}
                  handleChange={handleChange}
                  prevStep={prevStep}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>
          </Col>
        </div>
      </div>
    </>
  );
};

export default IndiRegister;
