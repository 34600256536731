import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment/moment";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Grid, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import verifiedIcon from "../assets/Icons/Verified.png";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Col, Container, Row } from "react-bootstrap";
import { baseUrl } from "../utils/baseUrl";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableSkeleton from "../utils/TableSkeleton";
import kycDone from "../assets/images/kycDone.png";

const columnHelper = createMRTColumnHelper();

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});
const Client = () => {
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [mobile, setMobile] = useState("");
  const [designation, setDesignation] = useState("");
  const [clientCreatedDate, setclientCreatedDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinalLoading, setIsFinalLoading] = useState(false);

  const columns = [
    columnHelper.accessor("name", {
      header: "Investor Name",
      size: 30,
      Cell: ({ row }) => {
        const customerRefNo = row.original.customerRefNo;
        const handleClick = () => {
          if (customerRefNo === undefined) {
            toast.warning("Complete your KYC first");
          }
        };
        return (
          <Tooltip
            title={
              customerRefNo === undefined
                ? "Complete the KYC"
                : "View Transactions"
            }
          >
            <Link
              to={
                customerRefNo
                  ? `/dashboard/investor-view/${customerRefNo}`
                  : "#"
              }
              onClick={handleClick}
            >
              <Button>{row.original.name}</Button>
            </Link>
          </Tooltip>
        );
      },
    }),
    // columnHelper.accessor("name", {
    //   header: "Name",
    //   size: 30,
    // }),
    columnHelper.accessor("Email", {
      header: "Email",
      size: 30,
    }),
    columnHelper.accessor("mobileNo", {
      header: "Mobile No",
      size: 30,
    }),
    columnHelper.accessor("createdAt", {
      header: "Created At",
      size: 40,
      Cell: (cell) => (
        <div style={{ textAlign: "center" }}>
          {moment(cell.row.original.createdAt.split("T")[0]).format(
            "DD-MM-YYYY"
          )}
        </div>
      ),
    }),
  ];

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const url = `${baseUrl}/Client_List`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { headers });
      const employees = response.data.data;
      setLoading(false);

      employees?.map((item) => {
        setclientCreatedDate(item.createdAt);
      });
      setEmployeeList(employees);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const updateClient = async (clientId) => {
    try {
      const url = `${baseUrl}/client_update/${clientId}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const requestBody = {
        name: Name,
        Email: email,
        Designation: designation,
      };

      const response = await axios.post(url, requestBody, { headers });
      const data = response.data;
      if (data.error == true) {
        toast.error(data.message);
      } else {
        toast.success(data.message);

        setClient({
          name: "",
          email: "",
          Designation: "",
        });
      }
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  // -------------------------------create new employee----------------------

  const createClientApi = async () => {
    setIsFinalLoading(true); // Start loading
    try {
      const url = `${baseUrl}/client_create`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        name: client.name,
        Email: client.email,
        mobileNo: client.mobileNo,
      };

      const response = await axios.post(url, payload, { headers });
      const data = response.data;

      console.log(response.data, "response client");

      if (data.error === true) {
        toast.error(data.message);
      } else {
        setIsVerified(false);
        setOtpSent(false);
        Swal.fire({
          title: "Success!",
          text: `${data.message}`,
          icon: "success",
          confirmButtonText: "OK",
        });
        setClient({
          name: "",
          email: "",
          mobileNo: "",
        });
        fetchClients();
      }
    } catch (error) {
      console.error("Error connecting to API", error);
      toast.error("Failed to create client");
    } finally {
      setIsFinalLoading(false); // Stop loading
    }
  };

  const handleSendOtp = async () => {
    const email = client.email;
    const newErrors = {};
    if (!email) {
      toast.error("Email Id is required");
      newErrors.email = "Email is required";
      setErrors(newErrors);
      return;
    }
    setIsLoading(true); // Start loader
    try {
      const response = await axios.post(`${baseUrl}/verification/verifyEmail`, {
        email: client.email,
      });
      const tsTransId = response.data.message;
      const error = response.data.error;
      if (response.status === 200) {
        if (error === false) {
          setOtpSent(true);
          toast.success(tsTransId);
        } else {
          toast.error(tsTransId);
        }
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again.");
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  const handleVerifyOtp = async () => {
    const newErrors = {};
    if (!client.otp) {
      toast.error("Please enter the OTP");
      newErrors.otp = "Please enter the OTP";
      setErrors(newErrors);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/verification/verifyEotp`, {
        otp: client.otp,
      });
      const emailData = response.data.message;
      const error = response.data.error;
      if (response.status === 200) {
        if (error === false) {
          toast.success(emailData);
          setIsVerified(true);
        } else {
          toast.error(emailData);
        }
      } else {
        toast.error("Aadhar verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying Aadhar:", error);
      toast.error("Failed to verify Aadhar. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // ------------------------end---------------------------------

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    setEmail(employee.Email);
    setDesignation(employee.Designation);
    setMobile(employee.PhoneNo);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedEmployee(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSave = (employee) => {
    updateClient(employee._id);
    closeEditModal();
    fetchClients();
    if (employee.error == true) {
      toast.error(employee.message);
    } else {
      toast.success(employee.message);
    }
  };
  const handleExportData = () => {
    const rowData = employeeList.map((client) => ({
      name: client.name,
      email: client.Email,
      mobileNo: client.mobileNo,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      name: row.original.name,
      email: row.original.Email,
      mobileNo: row.original.mobileNo,
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: employeeList,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: true,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderRowActions: ({ row }) => [
      <Box sx={{ display: "flex" }}>
        <IconButton onClick={() => openEditModal(row.original)}>
          <EditIcon color="error" />
        </IconButton>

        <div
          onClick={() => openEditModal(row.original)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          
          <Link to="/dashboard/client_kyc" state={row.original}>
            {row.original.status == 3 ? (
              <div
                style={{
                  width: "5rem",
                  height: "2.7rem",
                }}
              >
                <img
                  src={kycDone}
                  alt=""
                  srcSet=""
                  style={{ marginTop: "-10px" }}
                />
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "large",
                    backgroundColor: "#4a2a7d",
                    borderRadius: "3px",
                    padding: "1px 10px",
                  }}
                >
                  KYC
                </span>
              </div>
            )}
          </Link>
        </div>
      </Box>,
    ],
    positionToolbarAlertBanner: "bottom",
    paginationDisplayMode: "pages",

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  const [client, setClient] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const handleClientChange = (e) => {
    const { id, value } = e.target;
    setClient({ ...client, [id]: value });
    setErrors({ ...errors, [id]: "" });
  };

  const validateForm = () => {
    let formValid = true;
    let newErrors = {};

    if (!client.name) {
      formValid = false;
      newErrors.name = "Name is required";
    }

    if (!client.email) {
      formValid = false;
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(client.email)) {
      formValid = false;
      newErrors.email = "Email is not valid";
    }

    if (!client.mobileNo) {
      formValid = false;
      newErrors.mobileNo = "Mobile No. is required";
    } else if (!/^\d{10}$/.test(client.mobileNo)) {
      formValid = false;
      newErrors.mobileNo = "Mobile No. must be 10 digits";
    }
    if (!client.otp) {
      formValid = false;
      newErrors.otp = "OTP is required";
    }

    setErrors(newErrors);
    return formValid;
  };

  const handleCreateClient = (e) => {
    e.preventDefault();
    if (validateForm()) {
      createClientApi();
    }
  };

  return (
    <div className="container-fluid">
      <div className="dashboard section">
        <ToastContainer />
        {selectedEmployee && (
          <Modal
            isOpen={editModalIsOpen}
            onRequestClose={closeEditModal}
            contentLabel="Edit Employee"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
              },
            }}
          >
            <h3 className="text-center fw-bold form_header ">Edit Client</h3>
            <form>
              <div className="form-group p-2">
                <label className="label-color">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={Name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="form-group p-2">
                <label className="label-color">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <Button
                variant="contained"
                type="button"
                className="btn btn-secondary mt-2"
                style={{ float: "left" }}
                onClick={closeEditModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="button"
                className="btn btn-primary mt-2"
                style={{ float: "right" }}
                onClick={() => handleSave(selectedEmployee)}
              >
                Update
              </Button>
            </form>
          </Modal>
        )}
      </div>
      <div className="dashboard section">
        <div className="row">
          <div
            className="col-md-12 ms-2 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Col
              xs={6}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h2
                className="TABLE_HEADING"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                }}
              >
                Add Investor
              </h2>
            </Col>
          </div>
        </div>
        <hr />
        <Card className="mb-4" style={{ width: "100%", height: "90%" }}>
          <Container fluid>
            <Row className="mb-4">
              <div xs={12} style={{ paddingTop: "1.5rem" }}>
                <Box
                  component="form"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    margin: "0.5rem",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        sx={{ marginBottom: 1 }}
                        id="name"
                        label="Name"
                        required
                        fullWidth
                        size="small"
                        variant="outlined"
                        type="text"
                        value={client.name}
                        onChange={handleClientChange}
                        error={!!errors.name}
                        helperText={errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {otpSent === false && (
                        <div
                          className="col-md-9"
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "96%",
                          }}
                        >
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="email"
                            name="email"
                            label="Email"
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            value={client.email}
                            onChange={handleClientChange}
                            error={!!errors.email}
                            helperText={errors.email}
                          />
                          <div className="col-md-3" style={{ width: "33%" }}>
                            <Button
                              xs={3}
                              md={6}
                              lg={12}
                              onClick={handleSendOtp}
                              variant="contained"
                              sx={{
                                marginBottom: 2,
                                width: "6rem",
                                padding: "8px",
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "#fff" }}
                                />
                              ) : (
                                "Get Otp"
                              )}
                            </Button>
                          </div>
                        </div>
                      )}
                      {isVerified === false && otpSent === true && (
                        <div
                          className="col-md-9"
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "96%",
                          }}
                        >
                          <TextField
                            sx={{ marginBottom: 1 }}
                            id="otp"
                            name="otp"
                            label="OTP"
                            required
                            fullWidth
                            size="small"
                            inputProps={{ maxLength: 6 }}
                            pattern="\d{6}"
                            variant="outlined"
                            type="text"
                            value={client.otp}
                            onChange={handleClientChange}
                            error={!!errors.otp}
                            helperText={errors.otp}
                          />
                          <div className="col-md-3" style={{ width: "33%" }}>
                            <Button
                              xs={3}
                              md={6}
                              lg={12}
                              onClick={handleVerifyOtp}
                              variant="contained"
                              sx={{
                                marginBottom: 2,
                                width: "6rem",
                                padding: "8px",
                              }}
                              disabled={isLoading} // Disable button when loading
                            >
                              {isLoading ? (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "#fff" }}
                                />
                              ) : (
                                "Verify"
                              )}
                            </Button>
                          </div>
                        </div>
                      )}

                      {isVerified === true && otpSent === true && (
                        <div
                          className="col-md-9"
                          style={{
                            display: "flex",
                            gap: "5px",
                            width: "96%",
                          }}
                        >
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            fullWidth
                            size="small"
                            variant="outlined"
                            type="text"
                            value={client.email}
                            disabled
                            style={{ borderColor: "green" }}
                          />
                          <img
                            src={verifiedIcon}
                            alt="verified-icon"
                            srcset=""
                            style={{ width: "40px", marginLeft: "5px" }}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextField
                        sx={{ marginBottom: 1 }}
                        id="mobileNo"
                        label="Mobile No."
                        required
                        fullWidth
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        variant="outlined"
                        type="text"
                        value={client.mobileNo}
                        onChange={handleClientChange}
                        error={!!errors.mobileNo}
                        helperText={errors.mobileNo}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      sx={{ marginTop: "0.3rem" }}
                    >
                      <Button
                        variant={isVerified ? "contained" : ""}
                        style={{
                          width: "100%",
                          color: "white",
                          backgroundColor: isVerified ? undefined : "gray",
                        }}
                        onClick={handleCreateClient}
                        disabled={!isVerified || isLoading}
                      >
                        {isFinalLoading ? (
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Row>
          </Container>
        </Card>
        <Row>
          <Col xs={12}>
            {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Client;
