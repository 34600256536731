import React, { useState } from "react";
import { TextField, Grid } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment/moment";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, Tooltip } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Col } from "react-bootstrap";
import TableSkeleton from "../../utils/TableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "../../utils/baseUrl";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("_id", {
    header: "Investor Id",
    size: 40,
    Cell: ({ cell }) => {
      const handleCopy = () => {
        navigator.clipboard.writeText(cell.row?.original?._id);
        toast.success("Copied to clipboard!");
      };
      return (
        <Tooltip title={cell.row?.original?._id}>
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("name", {
    header: "Investor Name",
    size: 40,
    Cell: (cell) => cell.row.original.name || "N/A",
  }),
  columnHelper.accessor("Email", {
    header: "Email",
    size: 40,
    Cell: (cell) => cell.row.original.Email || "N/A",
  }),
  columnHelper.accessor("mobileNo", {
    header: "Mobile No",
    size: 40,
    Cell: (cell) => cell.row.original.mobileNo || "N/A",
  }),
  columnHelper.accessor("PanNumber", {
    header: "Pan Number",
    size: 40,
    Cell: (cell) => cell.row.original.PanNumber || "N/A",
  }),
  columnHelper.accessor("dob", {
    header: "Date of Birth",
    size: 40,
    Cell: (cell) => cell.row.original.dob || "N/A",
  }),
  columnHelper.accessor("address_line_1", {
    header: "Address Line 1",
    size: 40,
    Cell: (cell) => cell.row.original.address_line_1 || "N/A",
  }),
  columnHelper.accessor("address_line_2", {
    header: "Address Line 2",
    size: 40,
    Cell: (cell) => cell.row.original.address_line_2 || "N/A",
  }),
  columnHelper.accessor("city", {
    header: "City",
    size: 40,
    Cell: (cell) => cell.row.original.city || "N/A",
  }),
  columnHelper.accessor("state", {
    header: "State",
    size: 40,
    Cell: (cell) => cell.row.original.state || "N/A",
  }),
  columnHelper.accessor("zip", {
    header: "Pin Code",
    size: 40,
    Cell: (cell) => cell.row.original.zip || "N/A",
  }),
  columnHelper.accessor("bank_account_no", {
    header: "A/c No",
    size: 40,
    Cell: (cell) => cell.row.original.bank_account_no || "N/A",
  }),
  columnHelper.accessor("bank_account_name", {
    header: "A/c Name",
    size: 40,
    Cell: (cell) => cell.row.original.bank_account_name || "N/A",
  }),
  columnHelper.accessor("ifsc_code", {
    header: "Ifsc Code",
    size: 40,
    Cell: (cell) => cell.row.original.ifsc_code || "N/A",
  }),
  columnHelper.accessor("uniqueId", {
    header: "Unique Id",
    size: 40,
    Cell: (cell) => cell.row.original.uniqueId || "N/A",
  }),
  columnHelper.accessor("status", {
    header: "KYC Status",
    size: 40,
    Cell: (cell) => {
      let statusText = "";
      let backgroundColor = "";

      switch (cell.row.original.status) {
        case 0:
          statusText = "No KYC";
          backgroundColor = "#4a2a7d"; // red for "No KYC"
          break;
        case 1:
        case 2:
          statusText = "Incomplete KYC";
          backgroundColor = "#4a2a7d"; // yellow for "Incomplete KYC"
          break;
        case 3:
          statusText = "KYC Done";
          backgroundColor = "#4a2a7d"; // green for "KYC Done"
          break;
        default:
          statusText = "N/A";
          backgroundColor = "#4a2a7d"; // grey for unknown status
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              backgroundColor: backgroundColor,
              padding: "5px 7px",
              borderRadius: "5px",
              fontSize: "13px",
              color: "white",
            }}
          >
            {statusText}
          </span>
        </div>
      );
    },
  }),

  columnHelper.accessor("createdAt", {
    header: "Created At",
    size: 40,
    Cell: ({ cell }) => {
      const updatedAt = cell.row.original.updatedAt;
      return (
        <div style={{ textAlign: "center" }}>
          {moment(updatedAt).format("DD-MM-YYYY")}
        </div>
      );
    },
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const FileImportHistory = () => {
  const Token = sessionStorage.getItem("token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(false);

  useEffect(() => {
    fetchData();
  }, [check]);

  const fetchData = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      name: name,
      startDate: startDate,
      endDate: endDate,
    });
    try {
      const url = `${baseUrl}/report/clientImportHistory?${queryParams.toString()}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      if (response.data.error === false) {
        setLoading(false);
        setData(response.data.data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to API", error);
    }
  };

  // Handle Search
  const handleSearch = () => {
    fetchData();
  };

  const handleRefresh = () => {
    setName("");
    setStartDate("");
    setEndDate("");
    setCheck(!check);
  };

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => ({
      investorId: row.original._id || "N/A",
      investorName: row.original.name || "N/A",
      email: row.original.Email || "N/A",
      mobileNo: row.original.mobileNo || "N/A",
      panNumber: row.original.PanNumber || "N/A",
      dob: row.original.dob || "N/A",
      addressLine1: row.original.address_line_1 || "N/A",
      addressLine2: row.original.address_line_2 || "N/A",
      city: row.original.city || "N/A",
      state: row.original.state || "N/A",
      pinCode: row.original.zip || "N/A",
      bankAccountNo: row.original.bank_account_no || "N/A",
      bankAccountName: row.original.bank_account_name || "N/A",
      ifscCode: row.original.ifsc_code || "N/A",
      uniqueId: row.original.uniqueId || "N/A",
      status: row.original.status || "N/A",
      createdAt: moment(row.original.createdAt).format("DD-MM-YYYY") || "N/A", // Format date
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const rowData = data.map((data) => ({
      investorId: data._id || "N/A",
      investorName: data.name || "N/A",
      email: data.Email || "N/A",
      mobileNo: data.mobileNo || "N/A",
      panNumber: data.PanNumber || "N/A",
      dob: data.dob || "N/A",
      addressLine1: data.address_line_1 || "N/A",
      addressLine2: data.address_line_2 || "N/A",
      city: data.city || "N/A",
      state: data.state || "N/A",
      pinCode: data.zip || "N/A",
      bankAccountNo: data.bank_account_no || "N/A",
      bankAccountName: data.bank_account_name || "N/A",
      ifscCode: data.ifsc_code || "N/A",
      uniqueId: data.uniqueId || "N/A",
      status: data.status || "N/A",
      createdAt: moment(data.createdAt).format("DD-MM-YYYY") || "N/A", // Format date
    }));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          onClick={() => handleExportData(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div
          className="col-md-12 mt-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={6}
            style={{ width: "55%", display: "flex", alignItems: "center" }}
          >
            <h2
              className="TABLE_HEADING"
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0px",
              }}
            >
              File Import History
            </h2>
          </Col>
        </div>
      </div>
      <hr />
      <div className="mb-4">
        <div className="search-container p-4 border rounded">
          <Grid container spacing={3}>
            {/* Name Input */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Start Date and End Date Inputs */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                inputProps={{ style: { height: "6px" } }}
              />
            </Grid>

            {/* Buttons in the Same Row */}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    startIcon={<RefreshIcon />}
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
    </div>
  );
};

export default FileImportHistory;
