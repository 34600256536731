import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import batukLogo from "../assets/images/Batuk Logo.png";
import MenuBar from "../assets/Icons/menuicon.png";
import ProfileLogo from "../assets/Icons/ProfileIcon.png";
import GoldImg from "../assets/Icons/GoldIcon.png";
import SilverImg from "../assets/Icons/silverIcon.png";
import Reports from "../assets/reportsIcons/Reports.png";
import ReportsHover from "../assets/reportsIcons/Reports 2.png";
import { Collapse } from "@mui/material";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { baseUrl } from "../utils/baseUrl";
import { navigationData, subNavigationData } from "../utils/navigationData";

const settings = ["Profile", "Reset Password", "Logout"];

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "73px",
      [theme.breakpoints.up("sm")]: {
        width: "73px",
      },
    }),
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  "&:hover .icon": {
    display: "none",
  },
  "&:hover .icon-hover": {
    display: "block",
  },
  ".icon-hover": {
    display: "none",
  },
}));

const defaultTheme = createTheme();

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4a2a7d",
    color: "white",
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid white",
    zIndex: 1500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#4a2a7d",
  },
}));

const ReusableListItem = ({
  to,
  hoverKey,
  activeKey,
  icon,
  hoverIcon,
  label,
  handleMouseEnter,
  handleMouseLeave,
  setActiveItem,
  hoverStates,
  activeItem,
  open,
}) => (
  <Link
    to={to}
    onMouseEnter={() => handleMouseEnter(hoverKey)}
    onMouseLeave={() => handleMouseLeave(hoverKey)}
  >
    <HtmlTooltip
      title={
        !open ? (
          <React.Fragment>
            <Typography color="inherit">{label}</Typography>
          </React.Fragment>
        ) : (
          ""
        )
      }
      placement="right"
      arrow
    >
      <ListItem
        disablePadding
        sx={{
          color: "white",
          backgroundColor: activeItem[activeKey] ? "#4a2a7d" : "inherit",
        }}
      >
        <ListItemButtonStyled
          sx={{
            minHeight: 48,
            justifyContent: "initial",
            px: 2.5,
            color: "white",
          }}
          onClick={() => setActiveItem({ [activeKey]: true })}
        >
          <ListItemIcon
            className={activeItem[activeKey] ? "activecolor" : ""}
            sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}
          >
            <img
              src={
                hoverStates[hoverKey] || activeItem[activeKey]
                  ? hoverIcon
                  : icon
              }
              alt={`${label} Logo`}
              width="35px"
              style={{ marginRight: "1rem" }}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                style={{
                  color:
                    hoverStates[hoverKey] || activeItem[activeKey]
                      ? "white"
                      : "#4A2A7D",
                }}
              >
                {label}
              </Typography>
            }
          />
        </ListItemButtonStyled>
      </ListItem>
    </HtmlTooltip>
  </Link>
);

const DropdownListItem = ({
  to,
  hoverKey,
  activeKey,
  icon,
  hoverIcon,
  label,
  handleMouseEnter,
  handleMouseLeave,
  setActiveItem,
  hoverStates,
  activeItem,
  open,
}) => (
  <Link
    to={to}
    onMouseEnter={() => handleMouseEnter(hoverKey)}
    onMouseLeave={() => handleMouseLeave(hoverKey)}
  >
    <HtmlTooltip
      title={
        !open ? (
          <React.Fragment>
            <Typography color="inherit">{label}</Typography>
          </React.Fragment>
        ) : (
          ""
        )
      }
      placement="right"
      arrow
    >
      <ListItem
        disablePadding
        sx={{
          color: "white",
          backgroundColor: activeItem[activeKey] ? "#4a2a7d" : "inherit",
        }}
      >
        <ListItemButtonStyled
          sx={{
            minHeight: 48,
            justifyContent: "initial",
            px: 3.5,
            color: "white",
          }}
          onClick={() => setActiveItem({ [activeKey]: true })}
        >
          <ListItemIcon
            className={activeItem[activeKey] ? "activecolor" : ""}
            sx={{ minWidth: 0, mr: 0, justifyContent: "center" }}
          >
            <img
              src={
                hoverStates[hoverKey] || activeItem[activeKey]
                  ? hoverIcon
                  : icon
              }
              alt={`${label} Logo`}
              width="35px"
              style={{ marginRight: "1rem" }}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                style={{
                  color:
                    hoverStates[hoverKey] || activeItem[activeKey]
                      ? "white"
                      : "#4A2A7D",
                }}
              >
                {label}
              </Typography>
            }
          />
        </ListItemButtonStyled>
      </ListItem>
    </HtmlTooltip>
  </Link>
);

export default function DashBoard() {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [silverData, setSilverData] = React.useState(null);
  const [goldData, setGoldData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [profileData, setProfileData] = React.useState({
    name: "",
    email: "",
  });
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  // Inside your component
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const Token = sessionStorage.getItem("token");

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    if (open === true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getProfileData = async () => {
    try {
      const url = `${baseUrl}/profile`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const profile = response.data.data[0];
      setProfileData(profile);
    } catch (error) {
      console.error("Error connecting to API", error);
    }
  };

  const id = "gold";
  const fetchLiveData = async (id, setData) => {
    try {
      const url = `${baseUrl}/liveprice/${id}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };
      const response = await axios.get(url, { headers });
      const data = response.data.data;
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching data for ${id}`, error);
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  const callLogoutAPI = async () => {
    try {
      const url = `${baseUrl}/partner/logout`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();
      if (data.message === "user logged out successfully") {
        navigate("/login");
      } else {
        alert("Issue in logout");
      }
      return data;
    } catch (error) {
      console.error("Error connecting to API", error);
      throw error;
    }
  };

  const handleLogout = () => {
    callLogoutAPI();
    navigate("/");
    sessionStorage.removeItem("token");
  };

  const [hoverStates, setHoverStates] = React.useState({
    home: false,
    clients: false,
    employees: false,
    sip: false,
    goldfd: false,
    proposal: false,
    sell: false,
    earning: false,
    reports: false,
    impfiles: false,
    spotgold: false,
    salesReports: false,
    sipMining: false,
    sipBusinessReport: false,
    fileImportHistory: false,
    complianceReport: false,
    inactiveClients: false,
    missingSip: false,
  });

  const [activeItem, setActiveItem] = React.useState({});

  const handleMouseEnter = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: true,
    }));
  };

  const handleMouseLeave = (item) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [item]: false,
    }));
  };

  React.useEffect(() => {
    fetchLiveData("silver", setSilverData);
    fetchLiveData("gold", setGoldData);
    getProfileData();
  }, [id]);

  React.useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/dashboard") {
      setActiveItem({ home: true });
    } else if (
      pathname === "/dashboard/client_table" ||
      pathname === "/dashboard/client_kyc" ||
      pathname.startsWith("/dashboard/investor-view")
    ) {
      setActiveItem({ clients: true });
    } else if (pathname === "/dashboard/employee_table") {
      setActiveItem({ employees: true });
    } else if (
      pathname === "/dashboard/sip" ||
      pathname === "/dashboard/create-sip" ||
      pathname.startsWith("/dashboard/final-create-sip")
    ) {
      setActiveItem({ sip: true });
    } else if (
      pathname === "/dashboard/gold-fd" ||
      pathname === "/dashboard/start-gold-fd" ||
      pathname.startsWith("/dashboard/create-gold-fd")
    ) {
      setActiveItem({ GOLDFD: true });
    } else if (
      pathname === "/dashboard/proposal" ||
      pathname === "/dashboard/create_proposal" ||
      pathname === "/dashboard/final_create"
    ) {
      setActiveItem({ proposal: true });
    } else if (
      pathname === "/dashboard/sell" ||
      pathname === "/dashboard/create_sell" ||
      pathname === "/dashboard/final_create_sell"
    ) {
      setActiveItem({ sell: true });
    } else if (pathname === "/dashboard/earning") {
      setActiveItem({ earning: true });
    } else if (pathname === "/dashboard/reports") {
      setActiveItem({ reports: true });
    } else if (pathname === "/dashboard/upload-file") {
      setActiveItem({ impfiles: true });
    } else if (pathname === "/dashboard/sales-reports") {
      setActiveItem({ salesReports: true });
    } else if (pathname === "/dashboard/reports") {
      setActiveItem({ reports: true });
    } else if (pathname === "/dashboard/sip-mining") {
      setActiveItem({ sipMining: true });
    } else if (pathname === "/dashboard/sip-business-report") {
      setActiveItem({ sipBusinessReport: true });
    } else if (pathname === "/dashboard/file-import-history") {
      setActiveItem({ fileImportHistory: true });
    } else if (pathname === "/dashboard/compliance-report") {
      setActiveItem({ complianceReport: true });
    } else if (pathname === "/dashboard/inactive-clients") {
      setActiveItem({ inactiveClients: true });
    } else if (pathname === "/dashboard/missing-sip") {
      setActiveItem({ missingSip: true });
    } else if (
      pathname === "/dashboard/spot-gold" ||
      pathname === "/dashboard/create_order" ||
      pathname.startsWith("/dashboard/product")
    ) {
      setActiveItem({ spotgold: true });
    } else {
      setActiveItem({});
    }
  }, [location.pathname]);

  const navColor = "#4a2a7d";

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} onClose={toggleDrawer}>
          <Toolbar disableGutters sx={{ backgroundColor: "#472878" }}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <img
                  src={MenuBar}
                  alt="batuk"
                  height="41px"
                  width="45px"
                  style={{
                    display: "flex",
                    marginRight: 1,
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              ></Menu>
              {!open && (
                <div style={{ padding: "4px" }}>
                  <img
                    src={batukLogo}
                    alt="batuk"
                    width="158px"
                    height="58px"
                    style={{ display: "flex", marginRight: "1rem" }}
                  />
                </div>
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <h6 style={{ display: "contents", color: "red" }}>Live</h6>
                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>Loading...</div>
                ) : (
                  <div className="livedata">
                    <pre
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0",
                      }}
                    >
                      <img
                        src={GoldImg}
                        alt=""
                        srcSet=""
                        style={{ width: "1rem" }}
                      />{" "}
                      ₹{goldData}/g
                    </pre>
                    <pre
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "0",
                      }}
                    >
                      <img
                        src={SilverImg}
                        alt=""
                        srcSet=""
                        style={{ width: "1rem" }}
                      />{" "}
                      ₹{silverData}/g
                    </pre>
                  </div>
                )}
              </div>
            </Box>
            <Box sx={{ flexGrow: 0 }}></Box>
            <Box sx={{ flexGrow: 0, marginRight: "1rem" }}>
              <Tooltip title="Open settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div className="welcome-name">
                    <Typography>Welcome</Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      {profileData.name}
                    </Typography>
                  </div>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <img src={ProfileLogo} alt="Profile Logo" width="37px" />
                  </IconButton>
                </div>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div style={{ padding: "8px" }}>
                  <Typography>{profileData.name}</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    Partner Code ( {profileData.userName} )
                  </Typography>
                </div>
                <hr style={{ padding: "0px", margin: "0px" }} />
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#4d16a4",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      if (setting === "Logout") {
                        handleLogout();
                      } else if (setting === "Profile") {
                        navigate("/dashboard/profile");
                        handleCloseUserMenu();
                      } else if (setting === "Reset Password") {
                        navigate("/dashboard/reset-password");
                        handleCloseUserMenu();
                      } else {
                        handleCloseUserMenu();
                      }
                    }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{ height: "100vh" }}>
          <DrawerHeader
            sx={{
              backgroundColor: navColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <img
                src={batukLogo}
                alt="batuk"
                width="160px"
                style={{ display: "flex", marginRight: "2rem" }}
              />
            </IconButton>
          </DrawerHeader>
          <List
            style={{ scrollbarWidth: "none" }}
            sx={{
              marginTop: "0px",
              overflowY: "auto",
              height: "100vh",
              backgroundColor: "#DCDAFF",
              overflowX: "hidden",
            }}
          >
            {navigationData.map((item) => (
              <ReusableListItem
                key={item.id}
                to={item.route}
                hoverKey={item.id}
                activeKey={item.id}
                icon={item.icon}
                hoverIcon={item.hoverIcon}
                label={item.name}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                setActiveItem={setActiveItem}
                hoverStates={hoverStates}
                activeItem={activeItem}
                open={open}
              />
            ))}

            <Link
              to="#"
              onMouseEnter={() => handleMouseEnter("reports")}
              onMouseLeave={() => handleMouseLeave("reports")}
            >
              <HtmlTooltip
                title={
                  !open ? (
                    <React.Fragment>
                      <Typography color="inherit">Reports</Typography>
                    </React.Fragment>
                  ) : (
                    ""
                  )
                }
                placement="right"
                arrow
              >
                <ListItem
                  disablePadding
                  sx={{
                    color: "white",
                    backgroundColor: activeItem.reports ? "#4a2a7d" : "inherit",
                  }}
                >
                  <ListItemButtonStyled
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: open ? 2.5 : 1.5,
                      color: "white",
                    }}
                    onClick={() => {
                      toggleDropdown();
                      setActiveItem({ reports: true });
                    }}
                  >
                    <ListItemIcon
                      className={activeItem.reports ? "activecolor" : ""}
                      sx={{
                        minWidth: 0,
                        mr: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          hoverStates.reports || activeItem.reports
                            ? ReportsHover
                            : Reports
                        }
                        alt="ImpFiles Logo"
                        width="35px"
                        style={{ marginRight: open ? "1rem" : "5px" }}
                      />
                      {!open &&
                        (!dropdownOpen ? (
                          <IoIosArrowDown
                            style={{
                              color:
                                activeItem.reports || hoverStates.reports
                                  ? "white"
                                  : "#4a2a7d",
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          />
                        ) : (
                          <IoIosArrowUp
                            style={{
                              color:
                                activeItem.reports || hoverStates.reports
                                  ? "white"
                                  : "#4a2a7d",
                            }}
                          />
                        ))}
                    </ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              hoverStates.reports || activeItem.reports
                                ? "white"
                                : "#4A2A7D",
                          }}
                        >
                          Reports
                        </Typography>
                      }
                    />
                    {!dropdownOpen ? (
                      <IoIosArrowDown
                        style={{
                          color:
                            activeItem.reports || hoverStates.reports
                              ? "white"
                              : "#4a2a7d",
                        }}
                      />
                    ) : (
                      <IoIosArrowUp
                        style={{
                          color:
                            activeItem.reports || hoverStates.reports
                              ? "white"
                              : "#4a2a7d",
                        }}
                      />
                    )}
                  </ListItemButtonStyled>
                </ListItem>
              </HtmlTooltip>
            </Link>
            {/* Dropdown items */}
            <Collapse
              in={dropdownOpen}
              timeout="auto"
              unmountOnExit
              style={{ marginTop: "1px" }}
            >
              {subNavigationData.map((item) => (
                <DropdownListItem
                  key={item.id}
                  to={item.route}
                  hoverKey={item.id}
                  activeKey={item.id}
                  icon={item.icon}
                  hoverIcon={item.hoverIcon}
                  label={item.name}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  setActiveItem={setActiveItem}
                  hoverStates={hoverStates}
                  activeItem={activeItem}
                  open={open}
                />
              ))}
            </Collapse>
          </List>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Main open={open} className="main">
            <Outlet />
          </Main>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
