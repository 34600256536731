import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/css/header.css";
import "../assets/css/signup.css";
import Ci from "../assets/images/Curated Icons.png";
import Cd from "../assets/images/Completely digitalised.png";
import Bs from "../assets/images/Bank Grade Security.png";
import Hc from "../assets/images/Help Center.png";
import image1 from "../assets/images/1.png";
import image2 from "../assets/images/2.png";
import image3 from "../assets/images/3.png";
import NavBar1 from "../components/Navbar";
import { baseUrl } from "../utils/baseUrl";

const Login = () => {
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const navigate = useNavigate();
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ emailOrUsername: "", password: "" });
  const [authError, setAuthError] = useState("");

  const isEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!emailOrUsername) {
      emailError = "Email/Username is required";
    } else if (!isEmail(emailOrUsername) && emailOrUsername.length < 3) {
      emailError = "Username must be at least 3 characters long";
    }

    if (!password) {
      passwordError = "Password is required";
    } else if (password.length < 6) {
      passwordError = "Password must be at least 6 characters long";
    }

    if (emailError || passwordError) {
      setErrors({ emailOrUsername: emailError, password: passwordError });
      return false;
    }

    return true;
  };

  const handleReCAPTCHAResponse = async (token) => {
    if (!token) {
      setAuthError("Please complete the reCAPTCHA verification.");
      return;
    }

    let loginIdentifier = isEmail(emailOrUsername)
      ? emailOrUsername.toLowerCase()
      : emailOrUsername.toUpperCase();

    try {
      const response = await axios.post(
        `${baseUrl}/login`,
        {
          email: loginIdentifier,
          password: password,
          // recaptchaToken: token,
        },
        {
          withCredentials: true,
        }
      );
      const { auth, message, token } = response.data;

      if (auth) {
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("message", message);
        if (
          message === "partner login successful" ||
          message === "employee login successful"
        ) {
          navigate("/dashboard");
        }
      } else {
        setAuthError(message || "Authentication failed");
      }
    } catch (error) {
      setAuthError("Wrong Credentials. Please try again.");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!validate()) return;

    // Execute reCAPTCHA v3
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, {
          action: "submit",
        })
        .then((token) => {
          handleReCAPTCHAResponse(token);
        })
        .catch(() => {
          setAuthError("reCAPTCHA verification failed. Please try again.");
        });
    });
  };

  return (
    <>
      <NavBar1 />
      <div fluid className="otp-form-container" style={{ height: "92.9vh" }}>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div
            className="text-center bg-light-primary full-height"
            style={{ flex: "0 0 41.666667%", maxWidth: "41.666667%" }}
          >
            <Carousel
              controls={false}
              indicators={false}
              interval={3000}
              pause={false}
              className="mt-xl-10 mt-lg-5 mt-md-3 mt-sm-1"
            >
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image1}
                  alt="First slide"
                  loading="lazy"
                  style={{ marginTop: "5rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image2}
                  alt="Second slide"
                  loading="lazy"
                  style={{ marginTop: "5rem" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 img-height"
                  src={image3}
                  alt="Third slide"
                  loading="lazy"
                  style={{ marginTop: "5rem" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div>
            <div
              className="otp-form d-flex flex-column justify-content-center"
              style={{ height: "80vh" }}
            >
              <div
                className="form-center"
                style={{ marginTop: "11rem", marginBottom: "3rem" }}
              >
                <h2>Get Started</h2>
                <p>Enter your email or username to login to your account.</p>
                <div
                  className="p-4 mx-auto"
                  style={{ width: "100%", maxWidth: "24rem" }}
                >
                  <form id="demo-form" onSubmit={submitHandler}>
                    <div className="mb-3">
                      <input
                        style={{
                          border: "2px solid #4a2a7d",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "100%",
                        }}
                        type="text"
                        placeholder="Enter email/username"
                        value={emailOrUsername}
                        onChange={(e) => setEmailOrUsername(e.target.value)}
                        id="name"
                      />
                      {errors.emailOrUsername && (
                        <div className="text-danger">
                          {errors.emailOrUsername}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        style={{
                          border: "2px solid #4a2a7d",
                          borderRadius: "8px",
                          fontSize: "15px",
                          width: "100%",
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        id="password"
                      />
                      {errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>
                    {authError && (
                      <div className="text-danger mb-3">{authError}</div>
                    )}
                    <p
                      style={{
                        marginTop: "1rem",
                        fontSize: "15px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Link to="/forgotpassword" className="text-color">
                        Forgot Password?
                      </Link>
                    </p>

                    <button
                      type="submit"
                      className="btn py-2 w-100 g-recaptcha"
                      style={{
                        backgroundColor: "#4a2a7d",
                        color: "white",
                        borderRadius: "30px",
                      }}
                      data-sitekey={siteKey}
                      data-callback="onSubmit"
                      data-action="submit"
                    >
                      Login
                    </button>
                  </form>
                </div>
                <div className="row features">
                  <div
                    className="d-flex justify-content-center mb-3 mb-md-0"
                    style={{ flex: "0 0 25%", maxWidth: "25%" }}
                  >
                    <img
                      src={Ci}
                      className="bottom-logo img-fluid"
                      alt="Curated Investing"
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center mb-3 mb-md-0"
                    style={{ flex: "0 0 25%", maxWidth: "25%" }}
                  >
                    <img
                      src={Cd}
                      className="bottom-logo img-fluid"
                      alt="Completely Digitalised"
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center mb-3 mb-md-0"
                    style={{ flex: "0 0 25%", maxWidth: "25%" }}
                  >
                    <img
                      src={Bs}
                      className="bottom-logo img-fluid"
                      alt="Bank Grade Security"
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center mb-3 mb-md-0"
                    style={{ flex: "0 0 25%", maxWidth: "25%" }}
                  >
                    <img
                      src={Hc}
                      className="bottom-logo img-fluid"
                      alt="Help Center"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
